import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {LabelComponent} from '../label/label.component';

@Component({
  selector: 'ms-form-text',
  templateUrl: './form-text.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormTextComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [LabelComponent],
})
export class FormTextComponent implements ControlValueAccessor {
  value: any;

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  // eslint-disable-next-line no-empty,@typescript-eslint/no-empty-function
  registerOnChange(_fn: any): void {}

  // eslint-disable-next-line no-empty,@typescript-eslint/no-empty-function
  registerOnTouched(_fn: any): void {}

  writeValue(value: any): void {
    this.value = value;
    this.cdRef.markForCheck();
  }
}
