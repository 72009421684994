import {hideAll, Plugin} from 'tippy.js';

export const hideOthersTippyPlugin: Plugin = {
  name: 'hideOthers',
  defaultValue: true,

  fn() {
    return {
      onShow() {
        hideAll({duration: 0});
      },
    };
  },
};
