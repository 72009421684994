import {Directive, HostBinding, Input} from '@angular/core';

@Directive({
  selector: '[msTab]',
  standalone: true,
})
export class TabDirective {
  @Input('msTab')
  name: any;

  @Input()
  @HostBinding('class.active')
  isActive: boolean;
}
