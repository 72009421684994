import {Directive, ElementRef, Host, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {CheckboxComponent} from '../../components/checkbox/checkbox.component';
import {startWith, takeUntil} from 'rxjs/operators';
import {Subject, merge} from 'rxjs';
import {TooltipService} from '../../tooltip';
import {ExtendedTippyInstance} from '@ngneat/helipopper';
import {Placement} from 'tippy.js';

@Directive({
  selector: 'ms-checkbox[msCheckboxTooltip]',
  standalone: true,
})
export class CheckboxTooltipDirective implements OnChanges, OnDestroy {
  @Input()
  toggleOnTooltip: string | null = null;

  @Input()
  toggleOffTooltip: string | null = null;

  @Input()
  isTooltipAvailable: boolean;

  @Input()
  tooltipPlacement = 'top';

  private readonly destroy$ = new Subject<void>();
  private readonly changed$ = new Subject<void>();

  private tooltip: ExtendedTippyInstance<string>;

  constructor(
    @Host() private readonly checkbox: CheckboxComponent,
    private readonly elementRef: ElementRef,
    private readonly tooltipService: TooltipService
  ) {}

  private destoryTooltip(): void {
    this.tooltip?.destroy();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    // Any change should destroy existing tooltip and create new one if available
    this.changed$.next();
    this.destoryTooltip();
    this.processChanges();
  }

  processChanges(): void {
    if (this.isTooltipAvailable) {
      this.checkbox.checked$
        .pipe(startWith(this.checkbox.checked), takeUntil(merge(this.changed$, this.destroy$)))
        .subscribe(checked => {
          this.destoryTooltip();
          const tooltip = checked ? this.toggleOnTooltip : this.toggleOffTooltip;
          if (!tooltip) {
            // If the tooltip value is null/undefined, tippy logs error "Type of content is not supported" when it tries to show the tooltip
            return;
          }
          this.tooltip = this.tooltipService.create(this.elementRef.nativeElement, tooltip, {
            placement: this.tooltipPlacement as Placement,
          });
        });
    }
  }

  ngOnDestroy(): void {
    this.changed$.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
