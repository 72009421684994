import {Pipe, PipeTransform} from '@angular/core';
import {formatCodeToHtml} from '@matchsource/utils';

@Pipe({
  name: 'format',
  standalone: true,
})
export class FormatPipe implements PipeTransform {
  transform(value: string): string {
    return formatCodeToHtml(value);
  }
}
