<div class="collapse-panel" [ngClass]="{collapsed: collapsed}">
    <div class="header">
        <div test-id="ctrl-collapse" class="ico-wrapper" (click)="onToggle($event)">
            <span class="collapse-ico"></span>
        </div>
        <div class="header-content">
            <ng-content select="ms-collapse-header-panel"></ng-content>
        </div>
    </div>
    <div class="content">
        <ng-content></ng-content>
    </div>
</div>

