import {
  AfterContentInit,
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  forwardRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnDestroy,
  QueryList,
} from '@angular/core';
import {RadioButtonComponent} from '../radio-button/radio-button.component';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {RADIO_GROUP, RadioGroup} from './declarations';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {uniqId} from '@matchsource/utils';

@Component({
  selector: 'ms-radio-group',
  standalone: true,
  imports: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupComponent),
      multi: true,
    },
    {
      provide: RADIO_GROUP,
      useExisting: RadioGroupComponent,
    },
  ],
  templateUrl: './radio-group.component.html',
  styleUrl: './radio-group.component.scss',
})
export class RadioGroupComponent implements RadioGroup, OnDestroy, AfterContentInit, ControlValueAccessor {
  private _value: any = null;

  private _selected: RadioButtonComponent | null = null;

  private _disabled = false;

  private _name: string = uniqId('radiogroup_');

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private changeFn: (value: any) => void = () => {};

  private destroy$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => any = () => {};

  @HostListener('click')
  onBlur() {
    this.onTouched?.();
  }

  @HostBinding('class')
  hostClass = 'outline';

  @Input()
  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
    this.updateRadioButtonNames();
  }

  @Input()
  get value(): any {
    return this._value;
  }

  set value(newValue: any) {
    if (this._value === newValue) {
      return;
    }

    this._value = newValue;

    this.updateSelectedRadioFromValue();
    this.checkSelectedRadioButton();
  }

  @Input()
  get selected() {
    return this._selected;
  }

  set selected(selected: RadioButtonComponent | null) {
    this._selected = selected;
    this.value = selected ? selected.value : null;
    this.checkSelectedRadioButton();
  }

  @Input({transform: booleanAttribute})
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
    this.markRadiosForCheck();
  }

  @ContentChildren(RadioButtonComponent, {
    descendants: true,
  })
  radioList: QueryList<RadioButtonComponent>;

  private cdRef = inject(ChangeDetectorRef);

  private markForCheck(): void {
    this.cdRef.markForCheck();
  }

  private updateRadioButtonNames(): void {
    this.radioList?.forEach(radio => {
      radio.name = this.name;
      radio.markForCheck();
    });
  }

  private updateSelectedRadioFromValue(): void {
    const isAlreadySelected = this._selected !== null && this._selected.value === this._value;

    if (this.radioList && !isAlreadySelected) {
      this._selected = null;
      this.radioList.forEach(radio => {
        radio.checked = this.value === radio.value;
        if (radio.checked) {
          this._selected = radio;
        }
      });
    }
  }

  private markRadiosForCheck(): void {
    this.radioList?.forEach(radio => radio.markForCheck());
  }

  private checkSelectedRadioButton() {
    if (this._selected && !this._selected.checked) {
      this._selected.checked = true;
    }
  }

  // @TODO: Need to refactor it and remove this method. Use class(es) from radio-group element to change styles of radio buttons. It helps to avoid detectChanges() call.
  private updateRadioClasses(): void {
    this.radioList?.forEach(radio => {
      radio.inputClasses = 'btn-check';
      radio.labelClasses = 'btn btn-outline-primary';

      radio.detectChanges();
    });
  }

  registerOnChange(fn: any): void {
    this.changeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.markForCheck();
  }

  writeValue(value: any): void {
    this.value = value;
    this.markForCheck();
  }

  propagateChange(value: any): void {
    this.changeFn(value);
  }

  ngAfterContentInit() {
    this.radioList.changes.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.selected && !this.radioList.find(radio => radio === this.selected)) {
        this._selected = null;
      }

      this.updateRadioClasses();
    });

    this.updateRadioClasses();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
