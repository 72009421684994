import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import {RadioComponent} from '../radio/radio.component';
import {NgClass, NgIf} from '@angular/common';
import {coerceBoolean} from '@matchsource/utils';

// It doesn't support reactive form. Use radio-group instead of.

@Component({
  selector: 'ms-radio-button-group',
  templateUrl: './radio-button-group.component.html',
  styleUrls: ['./radio-button-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf],
})
export class RadioButtonGroupComponent implements OnChanges, AfterContentInit {
  @Input()
  label: string;

  @Input()
  required = false;

  @Input()
  hideLabel: string | boolean = false;

  @Input()
  isInvalid: boolean;

  @Input()
  isDeselectableGroup = false;

  @Input()
  disabled = false;

  @Input()
  outlineGroupClass: string;

  @Input()
  errorMessage: string;

  @Input()
  note: string;

  @Input()
  showNote: boolean;

  @Input()
  fieldNoteClass: string;

  @Input()
  hasLabelTemplate = false;

  @ContentChildren(RadioComponent)
  radioComponents: QueryList<RadioComponent>;

  showLabel = true;

  ngOnChanges(changes: SimpleChanges): void {
    if ('hideLabel' in changes || 'hasLabelTemplate' in changes) {
      this.showLabel = !coerceBoolean(this.hideLabel) && !this.hasLabelTemplate;
    }
  }

  ngAfterContentInit(): void {
    this.radioComponents.forEach(radio => {
      radio.inputClasses = 'btn-check';
      radio.labelClasses = 'btn btn-outline-primary';
      if (this.isDeselectableGroup) {
        radio.isUncheckAllowed = true;
      }
    });
  }
}
