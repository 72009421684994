import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[msSelectFile]',
  exportAs: 'msSelectFile',
  standalone: true,
})
export class SelectFileDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Input()
  fileExtensions: string[] = [];

  @Input()
  multiple = true;

  @Output()
  selectFile: EventEmitter<FileList> = new EventEmitter();

  private fileInput: HTMLInputElement;

  private accept: string = null;

  constructor(private readonly elementRef: ElementRef) {}

  ngAfterViewInit() {
    this.fileInput = document.createElement('input');
    this.fileInput.hidden = true;
    this.fileInput.type = 'file';
    this.fileInput.multiple = this.multiple;
    this.fileInput.setAttribute('test-id', 'ms-file-input');
    this.fileInput.addEventListener('change', event => {
      const target = event.target as HTMLInputElement;
      this.selectFile.emit(target.files);
      this.fileInput.value = '';
    });

    this.elementRef.nativeElement.appendChild(this.fileInput);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('fileExtensions' in changes) {
      if (this.fileExtensions && this.fileExtensions.length > 0) {
        this.accept = this.fileExtensions.map(extension => `.${extension}`).join(',');
      } else {
        this.accept = null;
      }
    }
  }

  @HostListener('click')
  onClick() {
    this.fileInput.accept = this.accept;
    this.fileInput.click();
  }

  ngOnDestroy() {
    this.elementRef.nativeElement.removeChild(this.fileInput);
  }
}
