import {
  AfterContentInit,
  Component,
  ChangeDetectionStrategy,
  ContentChildren,
  QueryList,
  Directive,
  ContentChild,
  forwardRef,
  input,
  booleanAttribute,
  Optional,
  Inject,
} from '@angular/core';
import {Observable} from 'rxjs';
import {ValidationErrors} from '@angular/forms';
import {toMap} from '@matchsource/utils';
import {ErrorProvider, ErrorToken} from './declarations';
import {FIELD_CONTAINER_TOKEN} from '../../constants';
import {FieldContainer} from '../../declarations';

@Directive({
  selector: '[msErrorProvider]',
  standalone: true,
})
export class ErrorProviderDirective {
  @ContentChild(forwardRef(() => ErrorsComponent), {static: false, descendants: true})
  customErrors: ErrorsComponent;
}

@Component({
  selector: 'ms-errors',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ErrorsComponent implements AfterContentInit {
  forContainer = input(false, {transform: booleanAttribute});

  @ContentChildren(ErrorToken as any)
  errors: QueryList<ErrorProvider>;

  private errorsMap: MsApp.Dictionary<ErrorProvider> = null;

  constructor(@Optional() @Inject(FIELD_CONTAINER_TOKEN) private readonly fieldContainer: FieldContainer) {}

  ngAfterContentInit(): void {
    if (this.errors) {
      this.errorsMap = toMap(this.errors.toArray(), 'customError.code');

      const forContainer = this.forContainer();
      if (forContainer && this.fieldContainer) {
        this.fieldContainer.setCustomErrors(this);
      }
    }
  }

  getMessage(errors: ValidationErrors | null): Observable<string> | null {
    if (errors === null || this.errorsMap === null) {
      return null;
    }

    const error = Object.keys(errors).find(errorCode => errorCode in this.errorsMap);
    if (!error) {
      return null;
    }

    return this.errorsMap[error].customError.message$;
  }
}
