import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MsTooltipDirective} from '../../directives/tooltip/tooltip.directive';
import {NgClass} from '@angular/common';
import {Props} from 'tippy.js';

@Component({
  selector: 'ms-text',
  templateUrl: './text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, MsTooltipDirective],
})
export class TextComponent {
  @Input() label: string;
  @Input() value: any;
  @Input() valueTooltip: string;
  @Input() fieldValueClass = '';
  @Input() tooltipOptions: Partial<Props> = {
    placement: 'bottom',
  };
}
