import {Observable} from 'rxjs';
import {InjectionToken} from '@angular/core';

export interface ErrorProvider {
  customError: {
    code: string;
    message$: Observable<string | null>;
  };
}

export const ErrorToken = new InjectionToken<ErrorProvider>('Error');
