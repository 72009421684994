<ul class="field-wrapper show-empty-label" [ngClass]="wrapperClassList">
  <li class="field-label" [innerHTML]="label" *ngIf="showLabel" [class.multiline-label]="multilineLabel"></li>
  <li class="field-label" *ngIf="hasLabelTemplate">
    <ng-content select=[labelTemplate]></ng-content>
  </li>
  <li class="field-element" [ngClass]="fieldClassList">
    <div class="component-wrapper" (focusin)="onFocus()" (focusout)="onBlur()">
      <ng-content></ng-content>
    </div>
  </li>
  <li class="field-note" test-id="field-note" *ngIf="showNote || (isInvalid && (errorMessage$ | async))" [ngClass]="fieldNoteClass">
    <span *ngIf="isInvalid else noteTpl" [innerHTML]="errorMessage$ | async"></span>
    <ng-template #noteTpl>
      <span [innerHTML]="note"></span>
    </ng-template>
  </li>
</ul>
