import {Directive, HostBinding, Input} from '@angular/core';

@Directive({
  selector: 'textarea[msTextarea]',
  exportAs: 'msTextarea',
  standalone: true,
})
export class TextareaDirective {
  @HostBinding('class.ps__child--consume') @Input() consumePerfectScroll = false;
}
