import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  booleanAttribute,
} from '@angular/core';
import {NgClass} from '@angular/common';

@Component({
  selector: 'ms-collapse-panel',
  templateUrl: './collapse-panel.component.html',
  styleUrls: ['./collapse-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass],
})
export class CollapsePanelComponent {
  @HostBinding('class')
  @Input()
  theme: 'compact' | '' = '';

  @Input()
  collapsed = false;

  @Input({transform: booleanAttribute})
  stopPropagation = false;

  @Output()
  toggle: EventEmitter<boolean> = new EventEmitter();

  onToggle(event: MouseEvent): void {
    if (this.stopPropagation) {
      event.stopPropagation();
    }

    this.collapsed = !this.collapsed;
    this.toggle.emit(this.collapsed);
  }
}
