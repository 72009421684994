import {
  Component,
  EmbeddedViewRef,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges,
  ChangeDetectorRef,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {
  DatePickerDirective,
  ECalendarValue,
  IDatePickerConfig,
  ISelectionEvent,
  DpDatePickerModule,
} from 'ng2-date-picker';
import {uniqId, parseDateTime, coerceBoolean} from '@matchsource/utils';
import {DateTime} from 'luxon';
import {DurationInput} from 'luxon/src/duration';
import {ReplaySubject} from 'rxjs';
import {DatetimeTimePipe} from './datetime-time.pipe';
import {NgxMaskDirective} from 'ngx-mask';
import {NgClass, NgIf, AsyncPipe} from '@angular/common';

type Meridiem = 'AM' | 'PM';

export enum Mode {
  Daytime = 'daytime',
  Day = 'day',
  Month = 'month',
}

const INNER_FORMAT = {
  [Mode.Daytime]: 'MM/DD/YYYY hh:mm A',
  [Mode.Day]: 'MM/DD/YYYY',
  [Mode.Month]: 'MM/YYYY',
};

const MODE_CONFIG = {
  [Mode.Daytime]: {
    FORMAT: 'MM/dd/yyyy hh:mm a',
    PLACEHOLDER: 'mm/dd/yyyy ##:## PM',
    // MASK: '00/00/0000 00:00 PM',
    MASK: null as string,
  },
  [Mode.Day]: {
    FORMAT: 'MM/dd/yyyy',
    PLACEHOLDER: 'mm/dd/yyyy',
    MASK: '00/00/0000',
  },
  [Mode.Month]: {
    FORMAT: 'MM/yyyy',
    PLACEHOLDER: 'mm/yyyy',
    MASK: '00/0000',
  },
};

@Component({
  selector: 'ms-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimePickerComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [NgClass, FormsModule, NgxMaskDirective, DpDatePickerModule, NgIf, AsyncPipe, DatetimeTimePipe],
})
export class DatetimePickerComponent implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {
  @Input()
  mode = Mode.Daytime;

  @Input()
  name: string;

  @Input()
  max: DateTime | Date | MsApp.DateString;

  @Input()
  min: DateTime | Date | MsApp.DateString;

  @Input()
  config: IDatePickerConfig;

  @Input()
  customClass: string;

  @Input()
  hideNote = false;

  @Output()
  changeValue: EventEmitter<any> = new EventEmitter();

  @Input()
  set disabled(val: boolean | string) {
    this._disabled = coerceBoolean(val);
  }

  get disabled() {
    return this._disabled;
  }

  @ViewChild('yearTpl', {static: true})
  yearRef: TemplateRef<any>;

  @ViewChild('timeTpl', {static: true})
  timeRef: TemplateRef<any>;

  @ViewChild('picker', {static: true})
  picker: DatePickerDirective;

  id: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _value: string;

  readonly value$ = new ReplaySubject<string>(1);

  get value(): string {
    return this._value;
  }

  set value(val: string) {
    this._value = val;

    // value$ and get/set is only needed to avoid the following issue with ngx-mask (in some cases initial input value is cleared by ngx-mask):
    // https://github.com/JsDaddy/ngx-mask/issues/1041
    //
    // This hack can be removed when the issue is fixed
    setTimeout(() => this.value$.next(val));
  }

  format: string;

  placeholder: string;

  mask: string;

  defaultConfig: IDatePickerConfig = {};

  patterns = {
    0: {pattern: new RegExp('\\d')},
    P: {
      pattern: new RegExp('[APap]'),
    },
    M: {
      pattern: new RegExp('[Mm]'),
    },
  };

  isYearActive = false;

  hours: number | null = null;

  isHoursActive = false;

  minutes: number | null = null;

  isMinutesActive = false;

  meridiem: Meridiem = 'PM';

  displayDate: DateTime;

  displayYear: number;

  displayMonth: string;

  private onValueChange: any;

  private onTouch: any;

  yearViewRef: EmbeddedViewRef<any>;

  timeViewRef: EmbeddedViewRef<any>;

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _disabled = false;

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly cdRef: ChangeDetectorRef
  ) {
    this.displayYear = +DateTime.now().toFormat('yyyy');
    this.displayMonth = DateTime.now().toFormat('MMM');
    this.displayDate = DateTime.now().set({day: 0});
    this.id = uniqId();
  }

  ngOnInit() {
    this.defaultConfig = {
      format: MODE_CONFIG[Mode.Daytime].FORMAT,
      firstDayOfWeek: 'su',
      monthFormat: 'MMM yyyy',
      disableKeypress: false,
      allowMultiSelect: false,
      openOnFocus: false,
      openOnClick: false,
      onOpenDelay: 0,
      weekDayFormat: 'ddd',
      showNearMonthDays: true,
      showWeekNumbers: false,
      enableMonthSelector: true,
      yearFormat: 'YYYY',
      showGoToCurrent: true,
      dayBtnFormat: 'DD',
      monthBtnFormat: 'MMM',
      hours12Format: 'hh',
      hours24Format: 'HH',
      meridiemFormat: 'A',
      minutesFormat: 'mm',
      minutesInterval: 1,
      secondsFormat: 'ss',
      secondsInterval: 1,
      showSeconds: false,
      showTwentyFourHours: false,
      timeSeparator: ':',
      multipleYearsNavigateBy: 10,
      showMultipleYearsNavigation: false,
      hideInputContainer: false,
      returnedValueType: ECalendarValue.String,
      unSelectOnClick: true,
      hideOnOutsideClick: true,
      min: null,
      max: null,
    };

    this.format = MODE_CONFIG[this.mode].FORMAT;
    this.placeholder = MODE_CONFIG[this.mode].PLACEHOLDER;
    this.mask = MODE_CONFIG[this.mode].MASK;
    this.config = {
      ...this.defaultConfig,
      ...this.config,
      format: INNER_FORMAT[this.mode],
      closeOnSelect: this.mode !== Mode.Daytime,
    };
    this.config.min = this.min ? parseDateTime(this.min).toFormat(this.format) : null;
    this.config.max = this.max ? parseDateTime(this.max).toFormat(this.format) : null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('min' in changes && this.config) {
      this.config = {
        ...this.config,
        min: this.min ? parseDateTime(this.min).toFormat(this.format) : null,
      };
    }

    if ('max' in changes && this.config) {
      this.config = {
        ...this.config,
        max: this.max ? parseDateTime(this.max).toFormat(this.format) : null,
      };
    }
  }

  ngOnDestroy(): void {
    this.value$.complete();
  }

  private injectControls() {
    this.injectYearControl();

    if (this.mode === Mode.Daytime) {
      this.injectTimeControl();
    }
  }

  private injectYearControl() {
    this.yearViewRef = this.viewContainerRef.createEmbeddedView(this.yearRef);
    this.yearViewRef.detectChanges();

    const outletElement = document.querySelector(`.dp-popup.${this.id} .dp-nav-header`);

    this.yearViewRef.rootNodes.forEach(rootNode => outletElement.appendChild(rootNode));
  }

  private injectTimeControl() {
    this.timeViewRef = this.viewContainerRef.createEmbeddedView(this.timeRef);
    this.timeViewRef.detectChanges();

    const outletElement = document.querySelector(`.dp-popup.${this.id} dp-time-select`);

    this.timeViewRef.rootNodes.forEach(rootNode => outletElement.appendChild(rootNode));
  }

  registerOnChange(fn: any): void {
    this.onValueChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: any): void {
    let date: DateTime = null;
    if (value) {
      date = this.mode === Mode.Daytime ? parseDateTime(value) : DateTime.fromFormat(value, this.format, {zone: 'utc'});
    }
    this.applyDate(date);
  }

  setValue(value: any) {
    let date: DateTime = null;
    if (value && DateTime.fromFormat(value, this.format).isValid) {
      date = value;
    }

    this.applyDate(value && DateTime.fromFormat(value, this.format));

    if (this.onValueChange) {
      this.onValueChange(date);
    }
  }

  private applyDate(date: DateTime) {
    if (date) {
      this.value = date.toFormat(this.format);
      this.hours = +date.toFormat('hh');
      this.minutes = +date.toFormat('mm');
      this.meridiem = date.toFormat('a') as Meridiem;
      this.displayDate = date;
    } else {
      this.value = '';
      this.displayDate = DateTime.now();
      this.hours = null;
      this.minutes = null;
      this.meridiem = 'PM';
    }

    this.displayYear = +this.displayDate.toFormat('yyyy');
    this.displayMonth = this.displayDate.toFormat('MMM');

    if (this.picker.api) {
      this.picker.api.moveCalendarTo(this.displayDate.toFormat(this.format));
    }

    if (this.value !== this.picker.formControl.value) {
      this.picker.formControl.reset(this.value);
    } else {
      this.cdRef.markForCheck();
    }
  }

  blur() {
    if (this.onTouch) {
      this.onTouch();
    }
  }

  onInputChange(event: any) {
    const value = event.target.value.split(/\s+/).join(' ').toUpperCase();
    const isValid = DateTime.fromFormat(value, this.format).toFormat(this.format) === value;
    if (isValid || value === '') {
      this.setValue(value);
    }
  }

  setDateTime(event: any) {
    const value = event.target.value.split(/\s+/).join(' ').toUpperCase();
    const isValid = DateTime.fromFormat(value, this.format).toFormat(this.format) === value;
    this.setValue(isValid ? value : null);
  }

  setDisplayYear() {
    this.displayDate = this.displayDate.set({year: this.displayYear});
    this.picker.api.moveCalendarTo(this.displayDate.toFormat(this.format));
  }

  setDate(event: ISelectionEvent) {
    if (event.type === 'selection') {
      let date = this.value ? DateTime.fromFormat(this.value, this.format) : DateTime.now();
      const eventDate = DateTime.fromISO((event.date as any).toISOString());
      const year = eventDate.year;
      const month = eventDate.month;
      const day = eventDate.day;
      date = date.set({
        year,
        month,
        day,
      });
      this.blur();
      this.setValue(date.toFormat(this.format));
    }
  }

  setHours(event: Event) {
    const hours = +(event.target as HTMLInputElement).value || 0;

    if (hours > 12) {
      return;
    }

    let date = this.value ? DateTime.fromFormat(this.value, this.format) : DateTime.now();
    date = date.set({hour: hours});
    this.setValue(date.toFormat(this.format));
  }

  setMinutes(event: Event) {
    const minutes = +(event.target as HTMLInputElement).value || 0;

    if (minutes > 59) {
      return;
    }

    let date = this.value ? DateTime.fromFormat(this.value, this.format) : DateTime.now();
    date = date.set({minute: minutes});
    this.setValue(date.toFormat(this.format));
  }

  toggleMeridiem() {
    let date = this.value ? DateTime.fromFormat(this.value, this.format) : DateTime.now();
    if (this.meridiem === 'AM') {
      date = date.plus({hours: 12});
    } else {
      date = date.minus({hours: 12});
    }
    this.setValue(date.toFormat(this.format));
  }

  onChange(value: any): void {
    this.changeValue.emit(value);
  }

  onLeftNav() {
    const delta: DurationInput = this.mode === Mode.Month ? {year: 1} : {months: 1};
    this.displayDate = this.displayDate.minus(delta);
    this.displayYear = +this.displayDate.toFormat('yyyy');
    this.displayMonth = this.displayDate.toFormat('MMM');
  }

  onRightNav() {
    const delta: DurationInput = this.mode === Mode.Month ? {year: 1} : {months: 1};
    this.displayDate = this.displayDate.plus(delta);
    this.displayYear = +this.displayDate.toFormat('yyyy');
    this.displayMonth = this.displayDate.toFormat('MMM');
  }

  onClose() {
    this.displayDate = this.value ? DateTime.fromFormat(this.value, this.format) : DateTime.now();

    this.displayYear = +this.displayDate.toFormat('yyyy');
    this.displayMonth = this.displayDate.toFormat('MMM');

    this.picker.api.moveCalendarTo(this.displayDate.toFormat(this.format));
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(element: HTMLElement) {
    this.isYearActive = !!element.closest(`.dp-popup.${this.id} .dp-nav-header .year`);
    this.isHoursActive = !!element.closest(`.dp-popup.${this.id} dp-time-select .hours`);
    this.isMinutesActive = !!element.closest(`.dp-popup.${this.id} dp-time-select .minutes`);
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  onOpen() {
    if (this.picker.api) {
      this.displayDate = this.displayDate || DateTime.now();
      this.picker.api.moveCalendarTo(this.displayDate.toFormat(this.format));
    }

    setTimeout(() => this.injectControls());
  }

  openDatePicker(): void {
    this.picker.api.open();
  }
}
