import {
  AfterViewInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  Host,
  Input,
  OnDestroy,
  QueryList,
} from '@angular/core';
import {RadioButtonGroupComponent} from '../../components/radio-button-group/radio-button-group.component';
import {FormControlName} from '@angular/forms';
import {startWith, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Directive({
  selector: '[msRadioButtonGroupValidation]',
  standalone: true,
})
export class RadioButtonGroupValidationDirective implements AfterViewInit, OnDestroy {
  @Input('msRadioButtonGroupValidation')
  errorMessage: string;

  @ContentChildren(FormControlName)
  formControl: QueryList<FormControlName>;

  private readonly destroy$ = new Subject<void>();

  constructor(
    @Host() private readonly radioButtonGroup: RadioButtonGroupComponent,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private init(): void {
    const control = this.formControl.first;
    if (!control) {
      return;
    }

    control.statusChanges.pipe(startWith(), takeUntil(this.destroy$)).subscribe(() => {
      const isInvalid = control.invalid && control.touched;
      this.radioButtonGroup.errorMessage = this.errorMessage;
      this.radioButtonGroup.isInvalid = isInvalid;
      this.cdRef.markForCheck();
    });
  }
}
