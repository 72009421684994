<input #input
       type="radio"
       [name]="name"
       [attr.id]="inputId"
       [disabled]="disabled"
       [readOnly]="readOnly"
       [checked]="checked"
       test-id="ms-radio-input"
       (change)="change($event)"
       [class]="inputClasses"
/>
<label [for]="inputId" tabindex="0" (blur)="blur()" (click)="click($event)" (focusin)="focusin($event)" [class]="labelClasses"><ng-content></ng-content></label>
