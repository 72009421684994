import {InjectionToken} from '@angular/core';

export interface RadioGroup {
  propagateChange: (value: any) => void,
  readonly value: any;
  selected: any;
  name: string;
}

export const RADIO_GROUP = new InjectionToken<RadioGroup>('RadioGroup');
