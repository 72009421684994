import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ErrorProvider, ErrorToken} from '../errors/declarations';

@Component({
  selector: 'ms-error',
  template: '',
  providers: [
    {
      provide: ErrorToken,
      useExisting: forwardRef(() => ErrorComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ErrorComponent implements OnDestroy, OnChanges, ErrorProvider {
  @Input()
  code!: string;

  @Input()
  message: string;

  readonly message$ = new BehaviorSubject<string | null>(null);

  get customError() {
    return this;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('message' in changes) {
      this.message$.next(this.message);
    }
  }

  ngOnDestroy(): void {
    this.message$.complete();
  }
}
