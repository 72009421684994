<div class="datetime-picker" [ngClass]="customClass">
    <input test-id="datetime-picker-ctrl"
           [id]="id"
           class="datetime-picker-ctrl"
           type="text"
           [value]="value$ | async"
           [ngModel]="value$ | async"
           [name]="name"
           [dpDayPicker]="$any(config)"
           #picker="dpDayPicker"
           theme="dp-material dp-ms {{id}}"
           [mask]="mask"
           [patterns]="patterns"
           [dropSpecialCharacters]="false"
           [mode]="mode"
           (onSelect)="setDate($event)"
           (change)="setDateTime($event)"
           (onChange)="onChange($event)"
           (input)="onInputChange($event)"
           (onLeftNav)="onLeftNav()"
           (onRightNav)="onRightNav()"
           (close)="onClose()"
           (open)="onOpen()"
           (blur)="blur()"
           [placeholder]="placeholder"
           [disabled]="disabled"
           autocomplete="off">
  <label class="datetime-picker-icon" (click)="openDatePicker()"></label>
    <ng-template #yearTpl>
        <span class="month">{{displayMonth}}</span>
        <div class="year">
            <input type="number" maxlength="4" class="ctrl" [(ngModel)]="displayYear" (change)="setDisplayYear()" *ngIf="isYearActive else yearValue"/>
            <ng-template #yearValue>
                <span class="value">{{displayYear}}</span>
            </ng-template>
        </div>
    </ng-template>

    <ng-template #timeTpl>
        <div class="hours">
            <input class="ctrl" max="12" mask="00" [ngModel]="hours" (blur)="setHours($event)" *ngIf="isHoursActive else hoursValue"/>
            <ng-template #hoursValue>
                <span class="value">{{hours | datetimeTime}}</span>
            </ng-template>
        </div>

        <span class="separator">:</span>

        <div class="minutes">
            <input class="ctrl" max="60" mask="00" [ngModel]="minutes" (blur)="setMinutes($event)" *ngIf="isMinutesActive else minutesValue"/>
            <ng-template #minutesValue>
                <span class="value">{{minutes | datetimeTime}}</span>
            </ng-template>
        </div>

        <span class="meridiem" (click)="toggleMeridiem()">{{meridiem}}</span>
    </ng-template>
</div>
