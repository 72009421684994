import {Injectable, OnDestroy} from '@angular/core';

export type RadioSelectionListener = (id: string, name: string) => void;

@Injectable({
  providedIn: 'root'
})
export class RadioSelectionService implements OnDestroy {
  private listeners: RadioSelectionListener[] = [];

  notify(id: string, name: string) {
    for (const listener of this.listeners) {
      listener(id, name);
    }
  }

  listen(listener: RadioSelectionListener): () => void {
    this.listeners.push(listener);
    return () => {
      this.listeners = this.listeners.filter((registered: RadioSelectionListener) => {
        return listener !== registered;
      });
    };
  }

  ngOnDestroy(): void {
    this.listeners = [];
  }
}
