export enum Buttons {
  Action = 'action',
  Secondary = 'secondary',
  Warning = 'warning',
  Icon = 'icon',
  Link = 'link',
  LinkInline = 'link-inline',
  Export = 'export',
  TabLink = 'tab-link',
  SideTabLink = 'side-tab-link',
}
