import {ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef} from '@angular/core';
import {NgIf, NgTemplateOutlet} from '@angular/common';
import {NgxSmartModalComponent} from 'ngx-smart-modal';

@Component({
  selector: 'ms-dialog',
  templateUrl: './dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgxSmartModalComponent, NgIf, NgTemplateOutlet],
})
export class DialogComponent {
  @Input()
  identifier!: string;

  @Input()
  customClass: string;

  @Input()
  hideDelay = 0;

  @ContentChild(TemplateRef)
  dialogTemplate: TemplateRef<any>;
}
