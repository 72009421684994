import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {uniqId} from '@matchsource/utils';
import {Subject} from 'rxjs';

@Component({
  selector: 'ms-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
  standalone: true,
})
export class CheckboxComponent implements ControlValueAccessor, OnDestroy {
  checked$ = new Subject<boolean>();

  @Input()
  disabled = false;

  // tslint:disable-next-line: variable-name
  private _checked = false;

  get checked() {
    return this._checked;
  }

  @Input()
  set checked(checked: boolean) {
    this._checked = checked;
    this.checked$.next(checked);
    this.cdRef.markForCheck();
  }

  @Input()
  readOnly = false;

  readonly inputId = uniqId('chk_');

  private onValueChange: any;

  private onTouch: any;

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  registerOnChange(fn: any): void {
    this.onValueChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdRef.markForCheck();
  }

  writeValue(obj: any): void {
    this.checked = !!obj;
    this.cdRef.markForCheck();
  }

  private toggle(): void {
    this.checked = !this.checked;
  }

  blur() {
    if (this.onTouch) {
      this.onTouch();
    }
  }

  click() {
    this.toggle();
    if (this.onValueChange) {
      this.onValueChange(this.checked);
    }
  }

  ngOnDestroy(): void {
    this.checked$.complete();
  }
}
