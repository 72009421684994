<button *ngIf="options.closeButton" (click)="remove()" aria-label="close" class="toast-close-button">
  <span aria-hidden="true">×</span>
</button>
<div *ngIf="title" [class]="options.titleClass" class="toast-title" [attr.aria-label]="title">
  {{ title }} <ng-container *ngIf="duplicatesCount">[{{ duplicatesCount + 1 }}]</ng-container>
</div>
<ng-container *ngTemplateOutlet="template; context: {$implicit: actions}"></ng-container>
<div *ngIf="message && options.enableHtml"
     [class]="options.messageClass"
     [innerHTML]="message">
</div>
<div *ngIf="message && !options.enableHtml"
     [class]="options.messageClass"
     [attr.aria-label]="message">
  {{ message }}
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
