import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[msPreventLineBreaks]',
  standalone: true,
})
export class PreventLineBreaksDirective {
  constructor(private readonly element: ElementRef) {}

  @HostListener('paste', ['$event'])
  onPaste(e: ClipboardEvent) {
    const fieldEl = this.element.nativeElement;
    const oldClipboardData = e.clipboardData.getData('text/plain');
    const newClipboardData = oldClipboardData.replace(/\r?\n|\r/g, '');

    const startPos = fieldEl.selectionStart;
    const endPos = fieldEl.selectionEnd;

    let newValue =
      fieldEl.value.substring(0, startPos) + newClipboardData + fieldEl.value.substring(endPos, fieldEl.value.length);

    if (fieldEl.maxLength < newValue.length) {
      newValue = newValue.substr(0, this.element.nativeElement.maxLength);
    }

    let cursorPos = endPos + newClipboardData.length;
    cursorPos = cursorPos > fieldEl.maxLength ? fieldEl.maxLength : cursorPos;

    fieldEl.focus();
    fieldEl.value = newValue;
    fieldEl.selectionEnd = cursorPos;
    fieldEl.dispatchEvent(new Event('input'));

    e.preventDefault();
  }

  @HostListener('keydown.shift.enter', ['$event'])
  @HostListener('keydown.enter', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    e.preventDefault();
  }
}
