import {Store} from '@ngxs/store';
import {RouterState} from './router.state';
import {firstValueFrom, Observable} from 'rxjs';
import {RouteParams} from './router.interfaces';
import {Navigate, NavigateBack} from './router.actions';
import {Injectable} from '@angular/core';
import {RawParams, StateObject, StateOrName, TransitionOptions} from '@uirouter/angular';
import {StateService} from '@uirouter/core';

@Injectable({providedIn: 'root'})
export class RouterStateService {
  constructor(
    private readonly store: Store,
    private readonly stateService: StateService
  ) {}

  get routeName(): string | null {
    return this.store.selectSnapshot(RouterState.routeName);
  }

  get routeName$(): Observable<string | null> {
    return this.store.select(RouterState.routeName);
  }

  get params(): RouteParams | undefined {
    return this.store.selectSnapshot(RouterState.params);
  }

  get params$(): Observable<RouteParams | undefined> {
    return this.store.select(RouterState.params);
  }

  getParam<T = string>(paramId: string, defaultValue?: T): T {
    return this.store.selectSnapshot(RouterState.param(paramId, defaultValue));
  }

  getParam$<T = string>(paramId: string, defaultValue?: T): Observable<T> {
    return this.store.select(RouterState.param(paramId, defaultValue));
  }

  getBooleanParam(paramId: string, defaultValue?: boolean): boolean {
    return this.store.selectSnapshot(RouterState.booleanParam(paramId, defaultValue));
  }

  getBooleanParam$(paramId: string, defaultValue?: boolean): Observable<boolean> {
    return this.store.select(RouterState.booleanParam(paramId, defaultValue));
  }

  navigate(path: StateOrName, queryParams?: RawParams, extras?: TransitionOptions): Observable<unknown> {
    return this.store.dispatch(new Navigate(path, queryParams, extras));
  }

  navigateWithCompletion(path: StateOrName, queryParams?: RawParams, extras?: TransitionOptions): Promise<unknown> {
    return firstValueFrom(this.navigate(path, queryParams, extras));
  }

  isChildOfParentState(childStateName: string, parentStateName: string): boolean {
    if (!parentStateName) {
      return false;
    }

    if (childStateName === parentStateName) {
      return true;
    }

    let childState: StateObject | undefined = this.stateService.get(childStateName)?.$$state?.();
    while (childState?.parent) {
      childState = childState.parent;

      if (childState.name === parentStateName) {
        return true;
      }
    }

    return false;
  }

  navigateBack(): void {
    this.store.dispatch(new NavigateBack());
  }
}
