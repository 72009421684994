<label [for]="inputId" (blur)="blur()" class="switch">
  <input
    [attr.test-id]="inputState"
    type="checkbox"
    [attr.id]="inputId"
    [disabled]="disabled"
    [checked]="checked"
    (click)="click()"
  />
  <span [msTooltip]="tooltip" class="slider round"></span>
</label>
<label [for]="inputId" (blur)="blur()"><ng-content></ng-content></label>
