import {Inject, Injectable, Injector} from '@angular/core';
import {DialogAdapter, DIALOG_ADAPTER, Content, DialogInstance} from '../declarations';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(@Inject(DIALOG_ADAPTER) private readonly dialog: DialogAdapter) {}

  close<TData = any>(id: string, data?: TData): void {
    this.getDialog(id)?.close<TData>(data);
  }

  closeAll(): void {
    this.dialog.closeAll();
  }

  closeAllExcludingIds(excludingIds: string[]): void {
    this.dialog.closeAllExcludingIds(excludingIds);
  }

  getDialog(id: string): DialogInstance {
    return this.dialog.getDialog(id);
  }

  create<T = any>(
    id: string,
    content: Content<T>,
    options?: Record<string, unknown>,
    parentInjector?: Injector
  ): DialogInstance {
    return this.dialog.create(id, content, options, parentInjector);
  }

  hasDialog(id: string): boolean {
    return this.dialog.hasDialog(id);
  }

  invoke<T = any>(id: string, component: Content<T>, options?: Record<string, unknown>, parentInjector?: Injector) {
    if (!this.dialog.hasDialog(id)) {
      return this.dialog.create(id, component, options, parentInjector);
    }

    return this.dialog.getDialog(id);
  }
}
