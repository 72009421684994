import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'ms-collapse-header-panel',
  templateUrl: './collapse-panel-header.component.html',
  styleUrls: ['./collapse-panel-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class CollapsePanelHeaderComponent {}
