import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  SimpleChanges,
} from '@angular/core';
import {UntypedFormControl, FormControlName, FormControlDirective, AbstractControl} from '@angular/forms';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';
import {hasRequiredValidator} from '../../utils';
import {ValidationService} from '../../services/validation.service';
import {ErrorProviderDirective, ErrorsComponent} from '../errors/errors.component';
import {NgClass, NgIf, AsyncPipe} from '@angular/common';
import {coerceBoolean} from '@matchsource/utils';
import {FieldContainer} from '../../declarations';
import {FIELD_CONTAINER_TOKEN} from '../../constants';

interface FieldClassList {
  marked?: boolean;

  [key: string]: boolean;
}

interface WrapperClassList {
  disabled?: boolean;
  required?: boolean;
  invalid?: boolean;
  active?: boolean;

  [key: string]: boolean;
}

type Mode = 'standard' | 'simple';

const MODE_CLASS_MAP: Record<Mode, string> = {
  simple: 'simple-field',
  standard: '',
};

type Layout = 'horizontal' | 'vertical';

const LAYOUT_CLASS_MAP: Record<Layout, string> = {
  horizontal: 'horizontal',
  vertical: '',
};

type Order = 'reverse' | 'standard';

const ORDER_CLASS_MAP: Record<Order, string> = {
  reverse: 'reverse',
  standard: '',
};

@Component({
  selector: 'ms-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf, AsyncPipe],
  providers: [
    {
      provide: FIELD_CONTAINER_TOKEN,
      useExisting: forwardRef(() => FormFieldComponent),
    },
  ],
})
export class FormFieldComponent implements OnChanges, AfterContentInit, OnDestroy, FieldContainer {
  @Input()
  fieldClass: string;

  @Input()
  wrapperClass: string;

  @Input()
  fieldNoteClass: string;

  @Input()
  label: string;

  @Input()
  hideLabel: string | boolean = false;

  showLabel = true;

  @Input()
  hasLabelTemplate = false;

  @Input()
  note: string;

  @Input()
  highlight = false;

  @Input()
  mode: Mode = 'standard';

  @Input()
  multilineLabel = false;

  @Input()
  layout: Layout = 'vertical';

  @Input()
  order: Order = 'standard';

  @Input()
  hideNote: string | boolean = false;

  @Input()
  fieldFormControl: AbstractControl;

  showNote = true;

  isInvalid = false;

  @ContentChild(FormControlName, {static: false})
  formControlNameDirective: FormControlName;

  @ContentChild(FormControlDirective, {static: false})
  formControlDirective: FormControlDirective;

  @ContentChild(ErrorsComponent, {static: false})
  customErrorsComponent: ErrorsComponent;

  private overriddenCustomErrorsComponent: ErrorsComponent;

  errorMessageEmitter: BehaviorSubject<Observable<string | null>> = new BehaviorSubject(null);

  errorMessage$: Observable<string | null>;

  private readonly destroy$ = new Subject<void>();

  fieldClassList: FieldClassList = {
    marked: false,
  };

  wrapperClassList: WrapperClassList = {
    disabled: false,
    invalid: false,
    active: false,
    required: false,
  };

  private required = false;

  private touched = false;

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly validation: ValidationService,
    @Optional() private readonly errorProvider: ErrorProviderDirective
  ) {
    this.errorMessage$ = this.errorMessageEmitter.pipe(switchMap(message$ => message$));
  }

  private get control() {
    if (this.fieldFormControl) {
      return this.fieldFormControl;
    }

    if (this.formControlNameDirective) {
      return this.formControlNameDirective.control;
    }

    if (this.formControlDirective) {
      return this.formControlDirective.control;
    }

    return null;
  }

  private get customErrors(): ErrorsComponent | null {
    if (this.overriddenCustomErrorsComponent) {
      return this.overriddenCustomErrorsComponent;
    }

    if (this.customErrorsComponent) {
      return this.customErrorsComponent;
    }

    return this.errorProvider?.customErrors ?? null;
  }

  ngAfterContentInit(): void {
    const {control} = this;

    if (control) {
      this.initControlHandlers(control);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setCustomErrors(errorsComponent: ErrorsComponent): void {
    this.overriddenCustomErrorsComponent = errorsComponent;
  }

  private initControlHandlers(control: AbstractControl | UntypedFormControl) {
    const required = hasRequiredValidator(control);
    this.required = required;
    if (required) {
      this.patchClassWrapperList({required: true});
    }

    (control as any).registerOnDisabledChange?.((isDisabled: boolean) => {
      this.updateState();

      if (!isDisabled) {
        this.touched = control.touched;
      }
    });

    control.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.updateState();
    });

    this.updateState();
  }

  private updateState() {
    const control = this.control;
    if (!control) {
      return;
    }

    const wrapperClassList: Partial<WrapperClassList> = {};
    const fieldClassList: Partial<FieldClassList> = {};

    if (this.wrapperClassList.disabled !== control.disabled) {
      wrapperClassList.disabled = control.disabled;
    }

    const isInvalid = (control.touched && control.invalid) || (this.highlight && control.invalid);
    if (this.wrapperClassList.invalid !== isInvalid) {
      wrapperClassList.invalid = isInvalid;
    }
    this.isInvalid = isInvalid;

    const isRequired = hasRequiredValidator(this.control) && this.control.enabled;
    if (isRequired !== this.wrapperClassList.required) {
      this.wrapperClassList.required = isRequired;
    }

    const isMarked = this.required && !control.disabled;
    if (this.fieldClassList.marked !== isMarked) {
      fieldClassList.marked = isMarked;
    }

    this.patchClassWrapperList(wrapperClassList);
    this.patchClassFieldList(fieldClassList);

    this.errorMessageEmitter.next(this.getErrorMessage(control));

    this.cdRef.markForCheck();
  }

  private getErrorMessage(control: AbstractControl) {
    if (this.customErrors) {
      const errorMessage = this.customErrors.getMessage(control.errors);

      if (errorMessage !== null) {
        return errorMessage;
      }
    }

    return this.validation.getErrorMessage(control.errors);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('fieldClass' in changes) {
      const change = changes.fieldClass;
      let newFieldClassList = {...this.fieldClassList, [this.fieldClass]: true};
      if (!change.firstChange && change.previousValue) {
        const {[change.previousValue as string]: _, ...clearFieldClassList} = newFieldClassList;
        newFieldClassList = clearFieldClassList;
      }

      this.fieldClassList = newFieldClassList;
    }

    if ('wrapperClass' in changes) {
      const change = changes.wrapperClass;
      let newWrapperClassList = {...this.wrapperClassList, [this.wrapperClass]: true};
      if (!change.firstChange && change.previousValue) {
        const {[change.previousValue as string]: _, ...clearWrapperClassList} = newWrapperClassList;
        newWrapperClassList = clearWrapperClassList;
      }

      this.wrapperClassList = newWrapperClassList;
    }

    if ('highlight' in changes) {
      this.updateState();
    }

    if ('hideLabel' in changes || 'hasLabelTemplate' in changes) {
      this.showLabel = !coerceBoolean(this.hideLabel) && !this.hasLabelTemplate;
    }

    if ('hideNote' in changes) {
      this.showNote = !coerceBoolean(this.hideNote);
    }

    if ('mode' in changes) {
      const prevModeClass = MODE_CLASS_MAP[changes.mode.previousValue];
      const newModeClass = MODE_CLASS_MAP[this.mode];

      const classPatch: MsApp.Dictionary<boolean> = {};
      if (prevModeClass) {
        classPatch[prevModeClass] = false;
      }

      if (newModeClass) {
        classPatch[newModeClass] = true;
      }

      this.patchClassWrapperList(classPatch);
    }

    if ('layout' in changes) {
      const prevLayoutClass = LAYOUT_CLASS_MAP[changes.layout.previousValue];
      const newLayoutClass = LAYOUT_CLASS_MAP[this.layout];

      const classPatch: MsApp.Dictionary<boolean> = {};
      if (prevLayoutClass) {
        classPatch[prevLayoutClass] = false;
      }

      if (newLayoutClass) {
        classPatch[newLayoutClass] = true;
      }

      this.patchClassWrapperList(classPatch);
    }

    if ('order' in changes) {
      const prevOrderClass = ORDER_CLASS_MAP[changes.order.previousValue];
      const newOrderClass = ORDER_CLASS_MAP[this.order];

      const classPatch: MsApp.Dictionary<boolean> = {};
      if (prevOrderClass) {
        classPatch[prevOrderClass] = false;
      }

      if (newOrderClass) {
        classPatch[newOrderClass] = true;
      }

      this.patchClassWrapperList(classPatch);
    }
  }

  patchClassFieldList(partialClassList: Partial<FieldClassList>) {
    this.fieldClassList = {...this.fieldClassList, ...partialClassList};
  }

  patchClassWrapperList(partialClassList: Partial<WrapperClassList>) {
    this.wrapperClassList = {...this.wrapperClassList, ...partialClassList};
  }

  onFocus() {
    this.patchClassWrapperList({active: true});
  }

  onBlur() {
    this.patchClassWrapperList({active: false});

    // Validate init value only if the control is touched and value is not changed. Angular doesn't trigger any event for this case.
    // Also validate if touched property is changed. We need this check because it is changed after statusChanges event.
    const {control} = this;
    if (control && (control.pristine || (control.touched && !this.touched))) {
      this.touched = control.touched;
      this.updateState();
    }
  }
}
