import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {provideTippyConfig} from '@ngneat/helipopper';
import {TippyConfig} from '@ngneat/helipopper/lib/tippy.types';
import {hideOthersTippyPlugin} from './plugins/hide-others';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class TooltipModule {
  static forRoot(config: Partial<TippyConfig> = {}): ModuleWithProviders<TooltipModule> {
    return {
      ngModule: TooltipModule,
      providers: [
        provideTippyConfig({
          ...config,
          plugins: [hideOthersTippyPlugin],
        }),
      ],
    };
  }
}
