import {ComponentRef} from '@angular/core';
import {NgxSmartModalComponent} from 'ngx-smart-modal';
import {DialogInstance} from '../../declarations';
import {firstValueFrom, merge, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

interface NgxSmartModalWrapperOptions {
  componentRef?: ComponentRef<NgxSmartModalComponent>;
}

export class NgxSmartModalWrapper implements DialogInstance {
  private closeResult: any;

  public readonly destroy$ = new Subject<void>();

  get isCloseInProgress(): boolean {
    return this.dialog.visible && !this.dialog.openedClass;
  }

  get componentRef(): ComponentRef<NgxSmartModalComponent> | undefined {
    return this.options?.componentRef;
  }

  constructor(
    public readonly dialog: NgxSmartModalComponent,
    private readonly options?: NgxSmartModalWrapperOptions
  ) {
    this.dialog.onOpen.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.init();
    });
  }

  get onClose() {
    return firstValueFrom(
      merge(this.dialog.onCloseFinished, this.dialog.onDismissFinished).pipe(
        map(() => this.closeResult),
        takeUntil(this.destroy$)
      )
    );
  }

  close(data?: any) {
    if (this.isCloseInProgress) {
      return;
    }

    this.closeResult = data;

    this.dialog.close();
  }

  open<T = any>(data?: T) {
    this.dialog.setData(data || {}, true);

    this.dialog.open();

    return this;
  }

  destroy() {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  private init() {
    this.closeResult = undefined;
  }
}

export const ModalInstanceFactory = (dialog: NgxSmartModalComponent, options?: NgxSmartModalWrapperOptions) =>
  dialog ? new NgxSmartModalWrapper(dialog, options) : null;
