<input #input
  type="radio"
  [name]="name"
  [attr.id]="inputId"
  [disabled]="disabled"
  [readOnly]="readOnly"
  test-id="ms-radio-input"
  (change)="change()"
  [class]="inputClasses"
/>
<label [for]="inputId" tabindex="0" (blur)="blur()" (click)="click()" [class]="labelClasses"><ng-content></ng-content></label>
