import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DIALOG_ADAPTER} from '../../declarations';
import {NgxSmartModalAdapterService} from './services/ngx-smart-modal-adapter.service';
import {NgxSmartModalFixService} from './services/ngx-smart-modal-fix.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
  providers: [
    {
      provide: DIALOG_ADAPTER,
      useClass: NgxSmartModalAdapterService,
    },
    NgxSmartModalFixService,
  ],
})
export class NgxSmartModalAdapterModule {}
