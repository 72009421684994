import {Inject, Injectable, Injector, PLATFORM_ID} from '@angular/core';
import {
  NgxSmartModalComponent,
  NgxSmartModalConfig,
  NgxSmartModalService,
  INgxSmartModalOptions,
  ModalInstance,
} from 'ngx-smart-modal';
import {Content, DialogAdapter, DialogInstance} from '../../../declarations';
import {isPlatformBrowser} from '@angular/common';
import {NgxSmartModalFixService} from './ngx-smart-modal-fix.service';
import {ModalInstanceFactory, NgxSmartModalWrapper} from '../utils';
import {take, takeUntil} from 'rxjs/operators';

@Injectable()
export class NgxSmartModalAdapterService implements DialogAdapter {
  private readonly dialogs: Map<string, NgxSmartModalWrapper> = new Map<string, NgxSmartModalWrapper>();

  private get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  constructor(
    private readonly dialog: NgxSmartModalService,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private readonly dialogFix: NgxSmartModalFixService
  ) {
    this.addEvents();
  }

  closeAll(): void {
    this.dialog.closeAll();
  }

  closeAllExcludingIds(excludingIds: string[]): void {
    if (!excludingIds || !excludingIds.length) {
      return;
    }

    const allOpenedModals = this.dialog.getOpenedModals();

    allOpenedModals.forEach((modalInstance: ModalInstance) => {
      if (!excludingIds.includes(modalInstance.id)) {
        modalInstance.modal.close();
      }
    });
  }

  create<T = any>(
    id: string,
    content: Content<T>,
    options: Record<string, unknown>,
    parentInjector: Injector
  ): DialogInstance {
    if (this.hasDialog(id)) {
      return this.getDialog(id);
    }

    const dialog = this.dialogFix.create<T>(id, content, options as INgxSmartModalOptions, parentInjector);

    return this.storeDialog(id, dialog);
  }

  getDialog(id: string): DialogInstance | null {
    if (this.hasDialog(id)) {
      return this.dialogs.get(id);
    }

    return this.storeDialog(id, this.dialog.get(id));
  }

  hasDialog(id: string): boolean {
    return this.dialogs.has(id);
  }

  private storeDialog(id: string, dialog: NgxSmartModalComponent | NgxSmartModalWrapper): DialogInstance | null {
    if (!dialog) {
      return null;
    }

    const preparedDialog = dialog instanceof NgxSmartModalComponent ? ModalInstanceFactory(dialog) : dialog;
    this.dialogs.set(id, preparedDialog);

    // If it is dynamic dialog then we will destroy it on close to avoid memory leaks
    if (preparedDialog.componentRef) {
      preparedDialog.dialog.onAnyCloseEventFinished
        .pipe(take(1), takeUntil(preparedDialog.destroy$))
        .subscribe(() => this.deleteDialog(id));
    }

    return preparedDialog;
  }

  private deleteDialog(id: string) {
    if (!this.dialogs.has(id)) {
      return;
    }

    const dialog = this.dialogs.get(id);
    this.dialogs.delete(id);
    dialog.destroy();
  }

  private addEvents() {
    if (!this.isBrowser) {
      return;
    }

    window.addEventListener(`${NgxSmartModalConfig.prefixEvent}delete`, ((e: CustomEvent) => {
      this.deleteDialog(e.detail.instance.id);
    }) as EventListener);
  }
}
