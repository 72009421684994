import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import {ButtonDirective} from '../../directives/button/button.directive';
import {ButtonSize} from '../../internal';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'ms-button-panel',
  templateUrl: './button-panel.component.html',
  styleUrls: ['./button-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ButtonPanelComponent implements OnChanges, AfterContentInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @Input()
  size: ButtonSize | null = null;

  @HostBinding('class.full-width')
  @Input()
  fullWidth = false;

  @ContentChildren(ButtonDirective)
  buttons: QueryList<ButtonDirective>;

  ngAfterContentInit(): void {
    this.buttons.changes.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.applySize(this.size);
    });

    this.applySize(this.size);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('size' in changes) {
      this.applySize(this.size);
    }
  }

  applySize(size: ButtonSize | null) {
    if (size && this.buttons) {
      this.buttons.forEach(button => (button.size = size));
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
