<input
  type="checkbox"
  [attr.id]="inputId"
  [disabled]="disabled"
  [checked]="checked"
  [readOnly]="readOnly"
  test-id="ms-checkbox-input"
  (click)="click()"
/>
<label [for]="inputId" (blur)="blur()" (click)="!readOnly"><ng-content></ng-content></label>
