import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {uniqId} from '@matchsource/utils';
import {RADIO_GROUP, RadioGroup} from '../radio-group/declarations';
import {RadioSelectionService} from '../../services/radio-selection.service';

@Component({
  selector: 'ms-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true,
    },
  ],
  standalone: true,
})
export class RadioButtonComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private _value: any = null;

  @Input()
  disabled = false;

  @Input()
  get value(): any {
    return this._value;
  }

  set value(value: any) {
    if (this._value !== value) {
      this._value = value;
      if (this.radioGroup !== null) {
        if (!this.checked) {
          this.checked = this.radioGroup.value === value;
        }
        if (this.checked) {
          this.radioGroup.selected = this;
        }
      }
    }
  }

  @Input()
  name: string;

  @Input()
  isUncheckAllowed = false;

  @Input()
  readOnly = false;

  @Input()
  inputClasses: string;

  @Input()
  labelClasses: string;

  @Input()
  @HostBinding('attr.data-align')
  align: 'left' | 'center' = 'left';

  @ViewChild('input', {static: true}) inputElement: ElementRef<HTMLInputElement>;

  readonly inputId = uniqId('rad_');

  private onValueChange: (value: any) => Record<string, unknown>;

  private onTouch: any;

  private _checked = false;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private removeSelectionListener: () => void = () => {};

  @Input({transform: booleanAttribute})
  get checked(): boolean {
    return this._checked;
  }

  set checked(value: boolean) {
    if (this._checked !== value) {
      this._checked = value;
      if (value && this.radioGroup && this.radioGroup.value !== this.value) {
        this.radioGroup.selected = this;
      } else if (!value && this.radioGroup && this.radioGroup.value === this.value) {
        this.radioGroup.selected = null;
      }

      if (value) {
        this.radioSelectionService.notify(this.inputId, this.name);
      }
      this.markForCheck();
    }
  }

  constructor(
    @Optional() @Inject(RADIO_GROUP) private readonly radioGroup: RadioGroup,
    private readonly cdRef: ChangeDetectorRef,
    private readonly radioSelectionService: RadioSelectionService
  ) {}

  ngOnInit(): void {
    if (this.radioGroup) {
      this.checked = this.radioGroup.value === this._value;

      if (this.checked) {
        this.radioGroup.selected = this;
      }

      this.name = this.radioGroup.name;
    }

    this.removeSelectionListener = this.radioSelectionService.listen((id, name) => {
      if (id !== this.inputId && name === this.name) {
        this.checked = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.removeSelectionListener();
  }

  registerOnChange(fn: any): void {
    this.onValueChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdRef.markForCheck();
  }

  writeValue(val: any): void {
    this.inputElement.nativeElement.checked = this.value === val;
    this.cdRef.markForCheck();
  }

  blur() {
    if (this.onTouch) {
      this.onTouch();
    }
  }

  change(event: Event): void {
    event.stopPropagation();

    if (!this.isUncheckAllowed && this.checked) {
      return;
    }

    if (!this.checked && !this.disabled) {
      const groupValueChanged = this.radioGroup && this.value !== this.radioGroup.value;
      this.checked = true;
      this.onValueChange?.(this.value);

      if (this.radioGroup) {
        this.radioGroup.propagateChange(this.value);
        if (groupValueChanged) {
          //this.radioGroup._emitChangeEvent();
        }
      }
    }
  }

  focusin(event: Event): void {
    event.stopPropagation();
  }

  click(event: Event): void {
    event.stopPropagation();
  }

  markForCheck(): void {
    this.cdRef.markForCheck();
  }

  detectChanges(): void {
    this.cdRef.detectChanges();
  }
}
