<ngx-smart-modal
  [identifier]="identifier"
  [customClass]="customClass"
  [hideDelay]="hideDelay"
  [refocus]="false"
  #modal
>
  <ng-container *ngIf="modal.visible">
    <ng-container
      [ngTemplateOutlet]="dialogTemplate"
      [ngTemplateOutletContext]="{$implicit: modal.getData(), modal: modal}"
    >
    </ng-container>
  </ng-container>
</ngx-smart-modal>
