import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  ViewChild,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {uniqId} from '@matchsource/utils';

// Use radio-button instead of this component. It provides support for radio-group

@Component({
  selector: 'ms-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true,
    },
  ],
  standalone: true,
})
export class RadioComponent implements ControlValueAccessor {
  @Input()
  disabled = false;

  @Input()
  value: any = null;

  @Input()
  name: string;

  @Input()
  isUncheckAllowed = false;

  @Input()
  readOnly = false;

  @Input()
  inputClasses: string;

  @Input()
  labelClasses: string;

  @Input()
  @HostBinding('attr.data-align')
  align: 'left' | 'center' = 'left';

  @ViewChild('input', {static: true}) inputElement: ElementRef<HTMLInputElement>;

  readonly inputId = uniqId('rad_');

  private onValueChange: (value: any) => Record<string, unknown>;

  private onTouch: any;

  private isChecked = false;

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  registerOnChange(fn: any): void {
    this.onValueChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdRef.markForCheck();
  }

  writeValue(val: any): void {
    this.inputElement.nativeElement.checked = this.value === val;
    this.cdRef.markForCheck();
  }

  blur() {
    if (this.onTouch) {
      this.onTouch();
    }
  }

  change() {
    let emitedValue = this.value;
    if (this.isUncheckAllowed && this.isChecked) {
      this.inputElement.nativeElement.checked = false;
      emitedValue = null;
    }

    if (this.onValueChange) {
      this.onValueChange(emitedValue);
    }
  }

  click() {
    this.isChecked = this.inputElement.nativeElement.checked;
    if (this.isUncheckAllowed && this.isChecked) {
      this.change();
    }
  }
}
