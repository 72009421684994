<ul class="field-wrapper" [ngClass]="{required: required, invalid: isInvalid, disabled: disabled}">
  <li class="field-label" [innerHTML]="label" *ngIf="showLabel"></li>
  <li class="field-label" *ngIf="hasLabelTemplate">
    <ng-content select=[labelTemplate]></ng-content>
  </li>
  <li class="outline-group"  [ngClass]="outlineGroupClass"><ng-content></ng-content></li>
  <li class="field-note" test-id="field-note" *ngIf="showNote || isInvalid" [ngClass]="fieldNoteClass">
    <span *ngIf="isInvalid else noteTpl">{{ errorMessage }}</span>
    <ng-template #noteTpl>
      <span>{{ note }}</span>
    </ng-template>
  </li>
</ul>
