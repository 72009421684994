import {StateOrName, RawParams, TransitionOptions} from '@uirouter/angular';
import {RouteSnapshot} from './router.interfaces';

export class Navigate {
  static readonly type = '[Router] Navigate';

  constructor(
    public path: StateOrName,
    public queryParams?: RawParams,
    public extras?: TransitionOptions
  ) {}
}

export class NavigateBack {
  static readonly type = '[Router] Navigate Back';
}

export class RouterNavigation {
  static readonly type = '[Router] RouterNavigation';

  constructor(public routeSnapshot: RouteSnapshot) {}
}

export class RouterError {
  static readonly type = '[Router] RouterError';

  constructor(public routeSnapshot: RouteSnapshot) {}
}

export class RouterSuccess {
  static readonly type = '[Router] RouterSuccess';

  constructor(public routeSnapshot: RouteSnapshot) {}
}

export type RouterAction = RouterNavigation | RouterError | RouterSuccess;
