import {
  Directive,
  ElementRef,
  Host,
  HostBinding,
  Inject,
  KeyValueDiffers,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarDirective,
} from 'ngx-perfect-scrollbar';

// hack to combine two directives with the same selector
// https://blog.nrwl.io/macgyvering-directives-3e41e7b93e1e
@Directive({
  selector: '[msScroll]',
  standalone: true,
})
export class PerfectScrollbarExtDirective extends PerfectScrollbarDirective {
  constructor(
    zone: NgZone,
    differs: KeyValueDiffers,
    elementRef: ElementRef,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) platformId: Object,
    @Optional() @Inject(PERFECT_SCROLLBAR_CONFIG) defaults: PerfectScrollbarConfigInterface
  ) {
    super(zone, differs, elementRef, platformId, defaults);
  }
}

@Directive({
  selector: '[msScroll]',
  standalone: true,
})
export class ScrollDirective implements OnInit, OnDestroy {
  @HostBinding('class.scroll-wrapper')
  readonly hostClass = true;

  private readonly domObserver = new MutationObserver(() => this.update());

  constructor(
    private readonly element: ElementRef,
    @Host() public innerScroll: PerfectScrollbarExtDirective
  ) {}

  ngOnInit() {
    this.domObserver.observe(this.element.nativeElement, {
      attributes: false,
      childList: true,
      subtree: true,
    });
  }

  private update() {
    this.innerScroll.update();
  }

  ngOnDestroy() {
    this.domObserver.disconnect();
  }
}
