import {Inject, Injectable, InjectionToken, Optional} from '@angular/core';
import {ValidationErrors} from '@angular/forms';
import {concat, isObservable, Observable, of} from 'rxjs';
import {combineAll, map} from 'rxjs/operators';

type ValidationMessageResolver = ((params: any) => Observable<string> | string) | Observable<string> | string;
export interface ValidationMessageResolvers {
  [key: string]: ValidationMessageResolver;
}

export const VALIDATION_MESSAGES = new InjectionToken<ValidationMessageResolvers>('ValidationMessages');

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  private messagesResolver: ValidationMessageResolvers;

  constructor(@Optional() @Inject(VALIDATION_MESSAGES) validationMessages: ValidationMessageResolvers[]) {
    this.messagesResolver = (validationMessages || []).reduce((acc, item) => {
      return {...acc, ...item};
    }, {});
  }

  registerErrorMessagesResolver(resolvers: ValidationMessageResolvers) {
    this.messagesResolver = {...this.messagesResolver, ...resolvers};
  }

  getErrorMessages(errors: ValidationErrors | null): Observable<string[] | null> {
    if (errors === null) {
      return of(null);
    }
    const resolvers: Observable<string | null>[] = Object.entries(errors).reduce((acc, error) => {
      const [key, params] = error;

      let validationMessageResolvers: ValidationMessageResolver[] = [];
      let resolver;
      if (key in this.messagesResolver) {
        resolver = this.messagesResolver[key];
        if (typeof resolver === 'function') {
          resolver = resolver(params);
        }

        if (!isObservable(resolver)) {
          resolver = of(resolver);
        }

        validationMessageResolvers = [resolver];
      }

      return [...acc, ...validationMessageResolvers];
    }, []);

    if (resolvers.length === 0) {
      return of(null);
    }

    return concat(resolvers).pipe(combineAll());
  }

  getErrorMessage(errors: ValidationErrors | null): Observable<string | null> {
    return this.getErrorMessages(errors).pipe(
      map(messages => {
        if (messages === null || messages.length === 0) {
          return null;
        }

        const [message] = messages;

        return message;
      })
    );
  }
}
