import {Injectable} from '@angular/core';
import {fromEvent, Observable} from 'rxjs';
import {debounceTime, share} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WindowResizeService {
  readonly resize$: Observable<Event>;

  constructor() {
    this.resize$ = fromEvent(window, 'resize').pipe(debounceTime(500), share());
  }
}
