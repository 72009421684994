import {Directive, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MsTooltipDirective} from './tooltip.directive';
import {Props} from 'tippy.js';

const VACCINATION_TOOLTIPS_OPTIONS: MsApp.Dictionary<Partial<Props>> = {
  date: {
    placement: 'top',
    maxWidth: '150',
  },
  status: {
    placement: 'bottom',
    allowHTML: true,
    maxWidth: '270',
  },
  manufacturer: {
    placement: 'right',
  },
};

@Directive({
  selector: '[msTooltip][vaccinationsTooltipOptions]',
  standalone: true,
})
export class VaccinationsTooltipOptionsDirective implements OnChanges {
  @Input()
  vaccinationsTooltipOptions: 'date' | 'status' | 'manufacturer';

  readonly options = VACCINATION_TOOLTIPS_OPTIONS;

  constructor(private readonly tooltipDirective: MsTooltipDirective) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('vaccinationsTooltipOptions' in changes && this.options[this.vaccinationsTooltipOptions]) {
      Object.assign(this.tooltipDirective.options, this.options[this.vaccinationsTooltipOptions]);
    }
  }
}
