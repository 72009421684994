export function onlyTippyProps(allProps: any) {
  const tippyProps = {};

  const ownProps = [
    'variations',
    'useHostWidth',
    'defaultVariation',
    'beforeRender',
    'lazy',
    'variation',
    'isEnabled',
    'className',
    'onlyTextOverflow',
    'data',
    'content',
    'context',
    'hideOnEscape',
    'customHost',
    'injector',
    'preserveView',
    'vcr',
  ];

  const overriddenMethods = ['onShow', 'onHidden', 'onCreate', 'onShowBefore'];

  Object.keys(allProps).forEach(prop => {
    if (!ownProps.includes(prop) && !overriddenMethods.includes(prop)) {
      tippyProps[prop] = allProps[prop];
    }
  });

  return tippyProps;
}

export function normalizeClassName(className: string | string[]): string[] {
  const classes = typeof className === 'string' ? className.split(' ') : className;

  return classes.map(klass => klass?.trim()).filter(Boolean);
}
