import {Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output} from '@angular/core';
import {TooltipService} from '../../tooltip';
import {ExtendedTippyInstance} from '@ngneat/helipopper';
import {Content} from '@ngneat/overview';
import {Props} from 'tippy.js';

@Directive({
  selector: '[msTooltip]',
  exportAs: 'msTooltip',
  standalone: true,
})
export class MsTooltipDirective implements OnChanges {
  @Input('msTooltip') tooltipValue: string | Content;

  @Input()
  options: Partial<Props>;

  @Input()
  display: boolean;

  @Output()
  events: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('click', ['$event.target'])
  onDocumentClick(target: HTMLElement) {
    target.blur();
    this.tooltip?.hide();
  }

  private tooltip: ExtendedTippyInstance<string>;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly tooltipService: TooltipService
  ) {}

  ngOnChanges(changes: any) {
    if ('tooltipValue' in changes && changes.tooltipValue.currentValue) {
      this.initTooltip();
    }
  }

  private initTooltip(): void {
    this.destroyTooltip();

    this.tooltip = this.tooltipService.create(this.elementRef.nativeElement, this.tooltipValue, {
      ...this.options,
      onShowBefore: this.onShowBefore.bind(this),
    });
  }

  onShowBefore(): boolean {
    return this.display;
  }

  private destroyTooltip(): void {
    this.tooltip?.destroy();
  }
}
