import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  OnChanges,
  OnDestroy,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import {TabDirective} from '../../directives/tabs/tab.directive';
import {takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'ms-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class TabGroupComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input()
  activeTab: string;

  @ContentChildren(TabDirective, {descendants: true})
  tabs: QueryList<TabDirective>;

  private readonly destroy$ = new Subject<void>();

  ngAfterViewInit() {
    setTimeout(() => this.setActive(this.activeTab));
    this.tabs.changes
      .pipe(
        tap(() => this.setActive(this.activeTab)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('activeTab' in changes) {
      if (this.tabs) {
        this.setActive(this.activeTab);
      }
    }
  }

  private setActive(tabName: string) {
    this.tabs.forEach(tab => {
      tab.isActive = tab.name === tabName;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
