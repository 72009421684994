<ul class="ms-field-view">
  <li class="field-label" [innerHTML]="label"></li>
  <li>
    <pre
      [ngClass]="fieldValueClass"
      [msTooltip]="valueTooltip"
      [options]="tooltipOptions"
      class="field-value"
      [innerHTML]="value"></pre>
  </li>
</ul>
