import {InjectionToken, Type} from '@angular/core';
import {Observable} from 'rxjs';

export type Content<T> = Type<T>;

export interface ToastParams {
  closeButton?: boolean;
  disableTimeOut?: boolean;
  enableHtml?: boolean;
  message?: string;
  messageClass?: string;
  positionClass?: string;
  progressBar?: boolean;
  tapToDismiss?: boolean;
  timeOut?: number;
  title?: string;
  titleClass?: string;
  toastClass?: string;
}

export interface ToastAdapter {
  info(params: ToastParams): any;
  infoWithCanceller<T>(params: ToastParams, canceller: Observable<T>): any;
  infoComponent<D, T>(params: ToastParams, component: Content<T>, data?: D): any;
  error(params: ToastParams): any;
  errorWithCanceller<T>(params: ToastParams, canceller: Observable<T>): any;
  errorComponent<D, T>(params: ToastParams, component: Content<T>, data?: D): any;
  success(params: ToastParams): any;
  successWithCanceller<T>(params: ToastParams, canceller: Observable<T>): any;
  successComponent<D, T>(params: ToastParams, component: Content<T>, data?: D): any;
}

export const TOAST_ADAPTER = new InjectionToken<ToastAdapter>('ToastAdapterService');
