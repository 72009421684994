import {InjectionToken, Injector, TemplateRef, Type} from '@angular/core';

export type Content<T> = string | TemplateRef<T> | Type<T>;

export interface DialogInstance {
  readonly onClose: Promise<any>;

  close<T = any>(data?: T): void;

  open<T = any>(data?: T): DialogInstance;
}

export interface DialogAdapter {
  closeAll(): void;

  closeAllExcludingIds(excludingIds: string[]): void;

  getDialog(id: string): DialogInstance | null;

  create<T>(
    id: string,
    content: Content<T>,
    options?: Record<string, unknown>,
    parentInjector?: Injector
  ): DialogInstance;

  hasDialog(id: string): boolean;
}

export const DIALOG_ADAPTER = new InjectionToken<DialogAdapter>('DialogAdapterService');
