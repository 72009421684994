import {Inject, Injectable} from '@angular/core';
import {ToastAdapter, TOAST_ADAPTER, ToastParams, Content} from '../declarations';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(@Inject(TOAST_ADAPTER) private readonly toastAdapter: ToastAdapter) {}

  info(params: ToastParams): any {
    return this.toastAdapter.info(params);
  }

  infoWithCanceller<T>(params: ToastParams, canceller: Observable<T>): any {
    return this.toastAdapter.infoWithCanceller(params, canceller);
  }

  infoComponent<D, T>(params: ToastParams, component: Content<T>, data?: D): any {
    return this.toastAdapter.infoComponent(params, component, data);
  }

  error(params: ToastParams): any {
    return this.toastAdapter.error(params);
  }

  errorWithCanceller<T>(params: ToastParams, canceller: Observable<T>): any {
    return this.toastAdapter.errorWithCanceller(params, canceller);
  }

  errorComponent<D, T>(params: ToastParams, component: Content<T>, data?: D): any {
    return this.toastAdapter.errorComponent(params, component, data);
  }

  success(params: ToastParams): any {
    return this.toastAdapter.success(params);
  }

  successWithCanceller<T>(params: ToastParams, canceller: Observable<T>): any {
    return this.toastAdapter.successWithCanceller(params, canceller);
  }

  successComponent<D, T>(params: ToastParams, component: Content<T>, data?: D): any {
    return this.toastAdapter.successComponent(params, component, data);
  }
}
