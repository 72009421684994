import {Injectable, OnDestroy} from '@angular/core';
import {Content, ToastAdapter, ToastParams} from '../../../declarations';
import {ToastrService} from 'ngx-toastr';
import {ActiveToast} from 'ngx-toastr/toastr/toastr.service';
import {IndividualConfig} from 'ngx-toastr/toastr/toastr-config';
import {removeUndefinedValuesInObject} from '../../../../utils/utils';
import {take, takeUntil} from 'rxjs/operators';
import {merge, Observable, Subject} from 'rxjs';

@Injectable()
export class NgxToastrAdapterService implements ToastAdapter, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(private readonly toastr: ToastrService) {}

  static getOverrides(params: ToastParams): Partial<IndividualConfig> {
    const overrides: Partial<IndividualConfig> = {
      closeButton: params.closeButton,
      disableTimeOut: params.disableTimeOut,
      enableHtml: params.enableHtml,
      messageClass: params.messageClass,
      positionClass: params.positionClass,
      progressBar: params.progressBar,
      tapToDismiss: params.tapToDismiss,
      timeOut: params.timeOut,
      titleClass: params.titleClass,
      toastClass: params.toastClass,
    };
    return removeUndefinedValuesInObject(overrides);
  }

  private handleCanceller<T>(toast: ActiveToast<any>, canceller: Observable<T>): void {
    merge(canceller, toast.onHidden)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(() => {
        this.toastr.clear(toast.toastId);
      });
  }

  info(params: ToastParams): ActiveToast<any> {
    return this.toastr.info(params.message, params.title, NgxToastrAdapterService.getOverrides(params));
  }

  infoWithCanceller<T>(params: ToastParams, canceller: Observable<T>): ActiveToast<any> {
    const toast = this.toastr.info(params.message, params.title, NgxToastrAdapterService.getOverrides(params));
    this.handleCanceller(toast, canceller);
    return toast;
  }

  infoComponent<T, D>(params: ToastParams, component: Content<T>, data?: D): ActiveToast<any> {
    const toast = this.toastr.info(params.message, params.title, {
      ...NgxToastrAdapterService.getOverrides(params),
      toastComponent: component,
    });
    if (data) {
      toast.toastRef.componentInstance.data = data;
    }
    return toast;
  }

  error(params: ToastParams): ActiveToast<any> {
    return this.toastr.error(params.message, params.title, NgxToastrAdapterService.getOverrides(params));
  }

  errorWithCanceller<T>(params: ToastParams, canceller: Observable<T>): ActiveToast<any> {
    const toast = this.toastr.error(params.message, params.title, NgxToastrAdapterService.getOverrides(params));
    this.handleCanceller(toast, canceller);
    return toast;
  }

  errorComponent<T, D>(params: ToastParams, component: Content<T>, data?: D): ActiveToast<any> {
    const toast = this.toastr.error(params.message, params.title, {
      ...NgxToastrAdapterService.getOverrides(params),
      toastComponent: component,
    });
    if (data) {
      toast.toastRef.componentInstance.data = data;
    }
    return toast;
  }

  success(params: ToastParams): ActiveToast<any> {
    return this.toastr.success(params.message, params.title, NgxToastrAdapterService.getOverrides(params));
  }

  successWithCanceller<T>(params: ToastParams, canceller: Observable<T>): ActiveToast<any> {
    const toast = this.toastr.success(params.message, params.title, NgxToastrAdapterService.getOverrides(params));
    this.handleCanceller(toast, canceller);
    return toast;
  }

  successComponent<D, T>(params: ToastParams, component: Content<T>, data?: D): ActiveToast<any> {
    const toast = this.toastr.success(params.message, params.title, {
      ...NgxToastrAdapterService.getOverrides(params),
      toastComponent: component,
    });
    if (data) {
      toast.toastRef.componentInstance.data = data;
    }
    return toast;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
