import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {ControlValuesType} from './declarations';

interface FormControlConfigModel {
  defaultValue: string | boolean | number | null;
  validators?: ValidatorFn[] | null;
}

export interface IFormableComponent {
  disableAndResetFormControls(
    formControlNames: string[],
    isDisabled: boolean,
    defaultValues: ControlValuesType[]
  ): void;

  updateFormControlValidators(formControlNames: string[], mustAdd: boolean, validators: ValidatorFn[]): void;

  markFormControlsAsUntouched(formControlNames: string[]): void;
}

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class FormableComponent implements IFormableComponent {
  @Input()
  form: UntypedFormGroup;

  disableAndResetFormControls(
    formControlNames: string[],
    isDisabled: boolean,
    defaultValues: ControlValuesType[]
  ): void {
    formControlNames.forEach((name, index) => {
      const formControl = this.form.get(name);
      if (isDisabled) {
        formControl.markAsUntouched();
        formControl.markAsPristine();
        formControl.disable();
        formControl.setValue(defaultValues[index]);
      } else {
        formControl.enable();
      }
    });
  }

  disableFormControls(formControlNames: string[], isDisabled: boolean): void {
    formControlNames.forEach(name => {
      const formControl = this.form.get(name);
      if (isDisabled) {
        formControl.disable();
      } else {
        formControl.enable();
      }
    });
  }

  updateFormControlValidators(formControlNames: string[], mustAdd: boolean, validators = [Validators.required]): void {
    formControlNames.forEach(name => {
      const formControl = this.form.get(name);
      if (mustAdd) {
        formControl.addValidators(validators);
      } else {
        formControl.removeValidators(validators);
      }
      formControl.updateValueAndValidity();
    });
  }

  markFormControlsAsUntouched(formControlNames: string[]): void {
    formControlNames.forEach(name => {
      const formControl = this.form.get(name);
      formControl.markAsUntouched();
    });
  }

  setDefaultValues(formControlNames: string[], defaultValuesMap: MsApp.Dictionary<FormControlConfigModel>): void {
    formControlNames.forEach(name => {
      const formControl = this.form.get(name);
      formControl.setValue(defaultValuesMap[name]?.defaultValue || null);
    });
  }
}
