import {inject, Injectable, Injector} from '@angular/core';
import {ExtendedTippyInstance, TIPPY_CONFIG, TIPPY_REF, TippyService} from '@ngneat/helipopper';
import {Content, isComponent, isTemplateRef, ViewService} from '@ngneat/overview';
import {delegate, DelegateInstance, default as tippy} from 'tippy.js';
import omit from 'lodash-es/omit';
import {normalizeClassName, onlyTippyProps} from '../utils';

type ExtractCreateOptionsType<T> = T extends (host: any, content: any, options: Partial<infer R>) => any ? R : unknown;
type CreateOptions = ExtractCreateOptionsType<TippyService['create']>;

interface DelegateSelector {
  parent: Element;
  target: string;
}

export type ExtendedCreateOptions<T, P> = CreateOptions & {
  onShowBefore?: (instance: ExtendedTippyInstance<T>) => boolean | void;
  resolve?: (instance: ExtendedTippyInstance<T>) => Promise<P>;
};

type ExtendedTippyInstanceWithResolver<T, P> = ExtendedTippyInstance<T> & {
  resolvedData: P;
  resolved?: boolean;
  resolving?: boolean;
  hiddenCompleted: boolean;
};

enum ToltipCssClass {
  Hidden = 'hidden',

  Loading = 'cursor-progress',
}

const normalizeDelegateSelector = (selector: string | DelegateSelector): DelegateSelector => {
  if (!(typeof selector === 'string')) {
    return selector;
  }

  return {
    parent: document.body,
    target: selector,
  };
};

// It is adapted version of https://github.com/ngneat/helipopper/blob/master/projects/ngneat/helipopper/src/lib/tippy.service.ts
// The service supports "delegate" https://atomiks.github.io/tippyjs/v6/addons/#event-delegation tippy addon and async data resolvers
@Injectable({providedIn: 'root'})
export class TooltipService {
  // "any" type is used because it is not possible to retrieve a type for the config
  private readonly globalConfig = inject(TIPPY_CONFIG);

  constructor(
    private readonly view: ViewService,
    private readonly injector: Injector
  ) {}

  private prepareBaseConfig<T extends Content>(
    content: T,
    options: Partial<ExtendedCreateOptions<T, unknown>> = {}
  ): Partial<CreateOptions> {
    const config = {
      onShow: (instance: ExtendedTippyInstance<T>) => {
        if (options?.onShowBefore) {
          const onShowBeforeResult = options.onShowBefore(instance);
          if (onShowBeforeResult === false) {
            return false;
          }
        }

        if (!instance.$viewOptions) {
          instance.$viewOptions = {};

          if (isTemplateRef(content)) {
            instance.$viewOptions.context = {
              $implicit: instance.hide.bind(instance),
              ...options.context,
            };
          } else if (isComponent(content)) {
            instance.context = options.context;
            instance.data = options.data;
            instance.$viewOptions.injector = Injector.create({
              providers: [
                {
                  provide: TIPPY_REF,
                  useValue: instance,
                },
              ],
              parent: options.injector || this.injector,
            });
          }
        }
        if (!instance.view) {
          // @ts-expect-error it is not compatible types
          instance.view = this.view.createView(content, {...options, ...instance.$viewOptions});
        }
        instance.setContent(instance.view.getElement());
        return options?.onShow?.(instance);
      },
      onHidden: (instance: ExtendedTippyInstance<T>) => {
        if (!options.preserveView) {
          instance.view.destroy();
          instance.view = null;
        }
        options?.onHidden?.(instance);
      },
      ...onlyTippyProps(this.globalConfig),
      ...this.globalConfig.variations[options.variation || this.globalConfig.defaultVariation],
      ...onlyTippyProps(options),
      onCreate: (instance: ExtendedTippyInstance<T>) => {
        if (options.className) {
          for (const klass of normalizeClassName(options.className)) {
            instance.popper.classList.add(klass);
          }
        }
        this.globalConfig.onCreate?.(instance);
        options.onCreate?.(instance);
      },
    };

    return config;
  }

  private prepareExtendedConfig<T, P>(options: Partial<ExtendedCreateOptions<T, P>> = {}): Partial<CreateOptions> {
    const overriddenOptions: Partial<ExtendedCreateOptions<T, P>> = {};
    if (options.resolve) {
      overriddenOptions.onShowBefore = (instance: ExtendedTippyInstance<T>): void | false => {
        const extendedInstance = instance as ExtendedTippyInstanceWithResolver<T, P>;
        if (!extendedInstance.resolved || extendedInstance.resolving) {
          extendedInstance.hiddenCompleted = false;
          instance.reference.classList.add(ToltipCssClass.Loading);
          instance.popper.classList.add(ToltipCssClass.Hidden);

          if (!extendedInstance.resolving) {
            extendedInstance.resolving = true;
            options
              .resolve(instance)
              .then(data => {
                extendedInstance.resolving = false;
                extendedInstance.resolved = true;
                extendedInstance.resolvedData = data;

                instance.reference.classList.remove(ToltipCssClass.Loading);

                if (extendedInstance.hiddenCompleted) {
                  return;
                }

                extendedInstance.show();
              })
              .catch(() => {
                extendedInstance.resolving = false;
              });
          }

          return false;
        }
      };

      overriddenOptions.onUntrigger = (instance: ExtendedTippyInstance<T>): void => {
        const extendedInstance = instance as ExtendedTippyInstanceWithResolver<T, P>;

        if (!extendedInstance.resolved) {
          instance.reference.classList.remove(ToltipCssClass.Loading);
        }

        extendedInstance.hiddenCompleted = true;
      };

      overriddenOptions.onShow = (instance: ExtendedTippyInstance<T>): void | false => {
        const extendedInstance = instance as ExtendedTippyInstanceWithResolver<T, P>;
        if (extendedInstance.resolved) {
          instance.data = extendedInstance.resolvedData;
          setTimeout(() => {
            instance.popper.classList.remove(ToltipCssClass.Hidden);
            instance.popperInstance.forceUpdate();
          });
        }
      };
    }

    const extendedOptions = {
      ...omit(options, 'resolve'),
      ...overriddenOptions,
    };

    return extendedOptions;
  }

  create<T extends Content, P>(
    host: Element,
    content: T,
    options: Partial<ExtendedCreateOptions<T, P>> = {}
  ): ExtendedTippyInstance<T> {
    const extendedOptions = this.prepareBaseConfig(content, this.prepareExtendedConfig(options));

    return tippy(host, extendedOptions) as ExtendedTippyInstance<T>;
  }

  delegate<T extends Content, P>(
    target: DelegateSelector | string,
    content: T,
    options: Partial<ExtendedCreateOptions<T, P>> = {}
  ): DelegateInstance {
    const targetSelector = normalizeDelegateSelector(target);

    const extendedOptions = this.prepareBaseConfig(content, this.prepareExtendedConfig(options));

    return delegate(targetSelector.parent, {
      target: targetSelector.target,
      ...extendedOptions,
    });
  }
}
