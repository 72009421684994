import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {uniqId} from '@matchsource/utils';
import {MsTooltipDirective} from '../../directives/tooltip/tooltip.directive';

@Component({
  selector: 'ms-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleSwitchComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [MsTooltipDirective],
})
export class ToggleSwitchComponent implements ControlValueAccessor {
  @Input()
  disabled = false;

  @Input()
  checked = false;

  @Input()
  tooltip: string;

  @Output()
  changeValue: EventEmitter<boolean> = new EventEmitter();

  readonly inputId = uniqId('toggle-switch_');

  private onValueChange: any;

  private onTouch: any;

  inputState: string;

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  registerOnChange(fn: any): void {
    this.onValueChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdRef.markForCheck();
  }

  writeValue(obj: any): void {
    this.checked = !!obj;
    this.cdRef.markForCheck();
    this.inputState = this.checked ? 'ms-toggle-switch-on' : 'ms-toggle-switch-off';
  }

  private toggle(): void {
    this.checked = !this.checked;
  }

  blur() {
    if (this.onTouch) {
      this.onTouch();
    }
  }

  click() {
    this.toggle();
    if (this.onValueChange) {
      this.onValueChange(this.checked);
    }
    this.changeValue.emit(this.checked);
  }
}
