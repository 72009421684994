import {
  ApplicationRef,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Injector,
  TemplateRef,
  Type,
} from '@angular/core';
import {Content} from '../../../declarations';
import {INgxSmartModalOptions, NgxSmartModalComponent} from 'ngx-smart-modal';
import {DOCUMENT} from '@angular/common';
import {ModalInstanceFactory} from '../utils';

// This service based on original code of ngx-smart-modal.service.ts service and it fixes the following issue with dynamic dialogs:
// https://github.com/maximelafarie/ngx-smart-modal/issues/277
@Injectable()
export class NgxSmartModalFixService {
  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly appRef: ApplicationRef,
    private readonly injector: Injector,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  public create<T>(
    id: string,
    content: Content<T>,
    options: INgxSmartModalOptions = {},
    parentInjector: Injector = null
  ) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(NgxSmartModalComponent);
    const ngContent = this.resolveNgContent(content);

    const componentRef = componentFactory.create(parentInjector || this.injector, ngContent);

    if (content instanceof Type) {
      componentRef.instance.contentComponent = content;
    }

    componentRef.instance.identifier = id;
    componentRef.instance.refocus = false;
    componentRef.instance.dismissable = false;
    componentRef.instance.createFrom = 'service';

    if (typeof options.closable === 'boolean') {
      componentRef.instance.closable = options.closable;
    }
    if (typeof options.escapable === 'boolean') {
      componentRef.instance.escapable = options.escapable;
    }
    if (typeof options.dismissable === 'boolean') {
      componentRef.instance.dismissable = options.dismissable;
    }
    if (typeof options.customClass === 'string') {
      componentRef.instance.customClass = options.customClass;
    }
    if (typeof options.backdrop === 'boolean') {
      componentRef.instance.backdrop = options.backdrop;
    }
    if (typeof options.force === 'boolean') {
      componentRef.instance.force = options.force;
    }
    if (typeof options.hideDelay === 'number') {
      componentRef.instance.hideDelay = options.hideDelay;
    }
    if (typeof options.autostart === 'boolean') {
      componentRef.instance.autostart = options.autostart;
    }
    if (typeof options.target === 'string') {
      componentRef.instance.target = options.target;
    }
    if (typeof options.ariaLabel === 'string') {
      componentRef.instance.ariaLabel = options.ariaLabel;
    }
    if (typeof options.ariaLabelledBy === 'string') {
      componentRef.instance.ariaLabelledBy = options.ariaLabelledBy;
    }
    if (typeof options.ariaDescribedBy === 'string') {
      componentRef.instance.ariaDescribedBy = options.ariaDescribedBy;
    }
    if (typeof options.refocus === 'boolean') {
      componentRef.instance.refocus = options.refocus;
    }

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    this.document.body.appendChild(domElem);

    return ModalInstanceFactory(componentRef.instance, {componentRef});
  }

  private resolveNgContent<T>(content: Content<T>): any[][] | Text[][] {
    if (typeof content === 'string') {
      const element = this.document.createTextNode(content);
      return [[element]];
    }

    if (content instanceof TemplateRef) {
      const viewRef = content.createEmbeddedView(null as any);
      this.appRef.attachView(viewRef);
      return [viewRef.rootNodes];
    }

    return [];
  }
}
