import {Directive, HostBinding, Input, OnDestroy, Self} from '@angular/core';
import {NgSelectComponent} from '@ng-select/ng-select';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

const HOST_CLASS = 'ms-drop-down';

@Directive({
  selector: '[msDropdown]',
  standalone: true,
})
export class DropdownDirective implements OnDestroy {
  @HostBinding(`class.${HOST_CLASS}`)
  readonly hostClass = true;

  @HostBinding('class.ms-drop-down--multiline')
  @Input()
  multilineOptions = false;

  private readonly destroy$ = new Subject<void>();

  @HostBinding('class.ps__child--consume')
  readonly consumePerfectScroll = true;

  constructor(@Self() private readonly dropdown: NgSelectComponent) {
    this.init();
  }

  private init() {
    this.dropdown.searchable = false;
    this.dropdown.clearable = false;
    this.dropdown.selectOnTab = true;

    this.dropdown.classes = `${this.dropdown.classes || ''} ${HOST_CLASS}`;

    this.dropdown.openEvent.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.scrollToSelected();
    });
  }

  private scrollToSelected() {
    setTimeout(() => {
      const {dropdown} = this;
      if (dropdown.hasValue && dropdown.dropdownPanel) {
        const scrollElement = dropdown.dropdownPanel.scrollElementRef.nativeElement;
        const selectedElement = scrollElement.querySelector('.ng-option-selected') as HTMLElement;
        if (selectedElement) {
          scrollElement.scrollTop = selectedElement.offsetTop;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
