import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {TOAST_ADAPTER} from '../../declarations';
import {NgxToastrAdapterService} from './services/ngx-toastr-adapter.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
  providers: [
    {
      provide: TOAST_ADAPTER,
      useClass: NgxToastrAdapterService,
    },
  ],
})
export class NgxToastrAdapterModule {}
