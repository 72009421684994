import {Directive, EventEmitter, HostListener, Input, Optional, Output} from '@angular/core';
import {NgxSmartModalComponent} from 'ngx-smart-modal';
import {DialogService} from '../services/dialog.service';
import {DialogComponent} from '../components/dialog/dialog.component';

@Directive({
  selector: '[msModalClose]',
  standalone: true,
})
export class ModalCloseDirective<T> {
  @Input('msModalClose')
  returnValue: T;

  @Output()
  closeModal: EventEmitter<T> = new EventEmitter<T>();

  @HostListener('click')
  clickHandler() {
    this.closeModal.emit(this.returnValue);
    this.close();
  }

  private get modalId() {
    return this.dialog?.identifier || this.dialogWrapper?.identifier;
  }

  constructor(
    @Optional() private readonly dialog: NgxSmartModalComponent,
    @Optional() private readonly dialogWrapper: DialogComponent,
    private readonly dialogService: DialogService
  ) {
    if ((!this.dialog && !this.dialogWrapper) || !this.modalId) {
      throw new Error(
        'msModalClose directive should be used inside Dialog or DynamicDialog component and modal ID should be defined.'
      );
    }
  }

  private close() {
    this.dialogService.close<T>(this.modalId, this.returnValue);
  }
}
