import {Pipe, PipeTransform} from '@angular/core';
import isNumber from 'lodash-es/isNumber';

@Pipe({
  name: 'datetimeTime',
  standalone: true,
})
export class DatetimeTimePipe implements PipeTransform {
  transform(value: number | string): string {
    if (!isNumber(value)) {
      return '##';
    }

    return value < 10 ? `0${value}` : `${value}`;
  }
}
