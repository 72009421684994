import {Injectable, NgZone} from '@angular/core';
import {Action, createSelector, Selector, State, StateContext, Store} from '@ngxs/store';
import {Navigate, NavigateBack, RouterAction, RouterError, RouterNavigation, RouterSuccess} from './router.actions';
import {UIRouter, Transition, RejectType} from '@uirouter/angular';
import {RouteParams, RouteSnapshot} from './router.interfaces';

const buildRouteSnapshot = (transition: Transition): RouteSnapshot => ({
  params: transition.params() || {},
  name: transition.$to().name,
});

@State<RouteSnapshot>({
  name: 'router',
  defaults: {
    params: {},
    name: null,
  },
})
@Injectable()
export class RouterState {
  @Selector()
  static routeName(state: RouteSnapshot): string | null {
    return state.name;
  }

  @Selector()
  static params(state: RouteSnapshot): RouteParams | undefined {
    return state.params;
  }

  static param<T = any>(id: string, defaultValue?: T) {
    return createSelector([RouterState.params], (params: RouteParams) => params[id] || defaultValue);
  }

  static booleanParam<T = any>(id: string, defaultValue?: T) {
    return createSelector([RouterState.params], (params: RouteParams) => params[id] ?? defaultValue);
  }

  constructor(
    private readonly store: Store,
    private readonly ngZone: NgZone,
    private readonly router: UIRouter
  ) {
    this.setUpStateEvents(router);
  }

  @Action(Navigate)
  async navigate(_: StateContext<RouteSnapshot>, action: Navigate) {
    try {
      await this.ngZone.run(() => this.router.stateService.go(action.path, action.queryParams, action.extras));
    } catch (ex) {
      /* empty */
    }
  }

  @Action(NavigateBack)
  back() {
    return this.ngZone.run(() => window.history.back());
  }

  @Action([RouterSuccess])
  angularRouterAction(ctx: StateContext<RouteSnapshot>, action: RouterAction) {
    ctx.setState(action.routeSnapshot);
  }

  private setUpStateEvents(router: UIRouter): void {
    router.transitionService.onBefore({}, (transition: Transition) => this.dispatchRouterNavigation(transition));
    router.transitionService.onSuccess({}, (transition: Transition) => this.dispatchRouterSuccess(transition));
    router.transitionService.onError({}, (transition: Transition) => this.dispatchRouterError(transition));
  }

  private dispatchRouterNavigation(transition: Transition): void {
    this.store.dispatch(new RouterNavigation(buildRouteSnapshot(transition)));
  }

  private dispatchRouterError(transition: Transition): void {
    // if you click back button during redirection and you got white screen because you are not resolving , because of equal parents routes.
    // We need to do a reload.
    const transitionError = transition.error();
    if (!transitionError.detail && transitionError.type === RejectType.SUPERSEDED) {
      this.ngZone.run(() => location.reload());
      return;
    }
    this.store.dispatch(new RouterError(buildRouteSnapshot(transition)));
  }

  private dispatchRouterSuccess(transition: Transition): void {
    this.store.dispatch(new RouterSuccess(buildRouteSnapshot(transition)));
  }
}
