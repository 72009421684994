import {AfterViewInit, Directive, OnDestroy} from '@angular/core';
import {Select} from '@ngxs/store';
import {RouterState} from 'ngxs-ui-router';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TabGroupComponent} from '../../components/tab-group/tab-group.component';

@Directive({
  selector: '[msRouterTabs]',
  standalone: true,
})
export class RouterTabsDirective implements OnDestroy, AfterViewInit {
  private readonly destroy$ = new Subject<void>();

  @Select(RouterState.routeName)
  routeName$: Observable<string>;

  constructor(private readonly tabGroup: TabGroupComponent) {}

  ngAfterViewInit() {
    this.routeName$.pipe(takeUntil(this.destroy$)).subscribe(routeName => {
      const active = this.tabGroup.tabs.find(tab => routeName.includes(tab.name));
      if (active) {
        this.tabGroup.activeTab = active.name;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
