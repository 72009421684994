import {AfterViewInit, Directive, ElementRef, OnDestroy, Renderer2} from '@angular/core';
import {WindowResizeService} from '../../services/window-resize.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Directive({
  selector: '[msShowTooltipIfTruncated]',
  exportAs: 'msShowTooltipIfTruncated',
  standalone: true,
})
export class ShowTooltipIfTruncatedDirective implements AfterViewInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
    private readonly windowResizeService: WindowResizeService
  ) {
    const styles = {
      'text-overflow': 'ellipsis',
      overflow: 'hidden',
      'white-space': 'nowrap',
    };
    Object.keys(styles).forEach(newStyle => {
      this.renderer.setStyle(this.elementRef.nativeElement, `${newStyle}`, styles[newStyle]);
    });
    windowResizeService.resize$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.togglePointerEvents();
    });
  }

  ngAfterViewInit(): void {
    this.togglePointerEvents();
  }

  togglePointerEvents(): void {
    const doesTextFitContent = this.elementRef.nativeElement.offsetWidth >= this.elementRef.nativeElement.scrollWidth;

    if (doesTextFitContent) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'pointer-events', 'none');
      return;
    }

    this.renderer.setStyle(this.elementRef.nativeElement, 'pointer-events', 'auto');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
